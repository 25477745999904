import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, Validators, FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";
import { PageUtilService } from "app/jb/service/common/page-util.service";
import { Subscription } from "rxjs";

import { CompareValidator } from 'app/jb/validator/compare.validator';
import { sha256 } from 'js-sha256';
import { PasswordValidator } from 'app/jb/validator/password.validator';
import { PasswordService } from "app/jb/service/password/password.service";
import { AuthService } from "app/jb/service/common/auth.service";
import { ValueSharedService } from "app/jb/service/common/value-shared.service";

/**
 * パスワード変更画面
 * 
 * @author
 */
@Component({
  selector: "change-pass",
  templateUrl: "./change-pass.component.html",
  styleUrls: ["./change-pass.component.scss"],
})
export class ChangePassComponent implements OnInit, OnDestroy {
  changePassForm: FormGroup;
  appLayout: any;

  loading: boolean;
  messages: any;
  subscription: Subscription;

  constructor(
    private router: Router,
    private pageUtilService: PageUtilService,
    private fb: FormBuilder,
    private passwordService: PasswordService,
    private authService: AuthService,
    private valueSharedService: ValueSharedService,
  ) {
    this.loading = false;
    this.messages = pageUtilService.messages;
    this.subscription = new Subscription();
  }

  ngOnInit(): void {

    this.changePassForm = this.getDefaultForm();
    this.changePassForm.controls.password.setValidators([
      Validators.required,
      PasswordValidator.checkPassword(),
    ]);
    this.changePassForm.controls.passwordconfirmation.setValidators([
      Validators.required,
      CompareValidator.compare(this.changePassForm.controls.password),
      PasswordValidator.checkPassword(),
    ]);


    this.createAppLayout(this);
  }

  ngOnDestroy(): void {
    this.pageUtilService.clearSubjects();
    this.subscription.unsubscribe();
  }

  // 子コンポーネントのイベントハンドリング
  onChildEvent(event: any) {
    // ボタン
    if (event.type === "buttonClick") {
      if (event.from === "changePasswordButton") {
        this.updete();
      }
    }
  }

  /**
   * FormGroupの初期値取得
   */
  getDefaultForm(): FormGroup {
    return this.fb.group({
      password: [{ value: '', disabled: false }],
      passwordconfirmation: [{ value: '', disabled: false }],
    });
  }

  /**
   * パスワードチェック
   */
   check() {
    var mailaddress = this.authService.getUserInfomation().mailaddress;
    var password = this.changePassForm.controls.password.value;
    var splitmail = mailaddress.substr(0, mailaddress.indexOf('@'));
    if(mailaddress == password || splitmail == password) {
      alert("パスワードはメールアドレスとは別のものを登録してください。");
      return false;
    }
    return true;
  }

  /**
   * 更新ボタンクリック
   */
  updete() {
    if(this.check()){
      if (this.changePassForm.valid) {
        this.loading = true;
        const param = {
          modifiedby: this.authService.getUserInfomation().mailaddress,
          mailaddress: this.authService.getUserInfomation().mailaddress,
          password: sha256(this.changePassForm.controls.password.value)
        }

        this.passwordService
          .update(param)
          .then((res) => {
            this.pageUtilService.showToast("success", res.code, res.message);
            // this.procmode = "display";
            // this.changeScreenMode(this.procmode);

            this.loading = false;
            if (!this.valueSharedService.isfirst && !this.valueSharedService.isreset) {//ログイン後
              this.router.navigate(["/page/change-pass-success"]);
            } else {//初回ログイン or パスワードリセット
              this.router.navigate(["/change-pass-success"]);
            }
          })
          .catch((err) => {
            console.log(err);
            this.pageUtilService.setApiError(this.changePassForm, err);
            this.pageUtilService.showToast(
              "danger",
              err.error.code,
              err.error.message
            );
            this.loading = false;
          });
      }
    }
  }


  createAppLayout(_this) {
    this.appLayout = {
      cardList: [
        {
          name: "パスワード再設定",
          width: 12,
          status: "warning",
          rows: [
            {
              cols: [
                {
                  width: 2,
                },
                {
                  type: "input",
                  label: "新パスワード",
                  controlName: "password",
                  width: 8,
                  required: true,
                  inputType: "password",
                },
              ],
            },
            {
              cols: [
                {
                  width: 2,
                },
                {
                  type: "input",
                  label: "確認パスワード",
                  controlName: "passwordconfirmation",
                  width: 8,
                  required: true,
                  inputType: "password",
                },
              ],
            },
            {
              cols: [
                {
                  width: 2,
                },
                {
                  type: "button",
                  label: "送信",
                  controlName: "changePasswordButton",
                  width: 8,
                  status: "info",
                  style: "default",
                  onClickParam: "",
                  fullWidth: true,
                },
                {
                  width: 2,
                },
              ],
            },
          ],
        },
      ],
    };
  }
}