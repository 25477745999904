import { Subscription } from "rxjs";
import { PageUtilService } from "app/jb/service/common/page-util.service";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Component, Input, OnDestroy, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "jb-form-select",
  templateUrl: "./jb-form-select.component.html",
  styleUrls: ["./jb-form-select.component.scss"],
})
export class JbFormSelectComponent implements OnInit, OnDestroy {
  @Input() mode: "single" | "multiple" | undefined = "single";
  @Input() parent: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() optionCode: string;
  @Input() options: any;
  @Input() optionInclude: Array<string>; // 特定の選択肢にのみ限定する場合に設定
  // options: any;
  @Input() readonly = false;
  @Input() fieldSize: string = "medium";
  @Input() messages: any;
  @Output() selectedChange: EventEmitter<any>;

  subscription: Subscription;
  isMultiple: boolean = false;
  required: boolean;

  constructor(private pageUtilService: PageUtilService) {
    this.subscription = new Subscription();
    this.selectedChange = new EventEmitter<any>();
  }

  ngOnInit(): void {
    this.isMultiple = this.mode === "multiple";
    // optionsが直接指定されていたら監視しない
    if (!this.options) {
      this.subscription.add(
        this.pageUtilService.optionSubject.subscribe(
          (option: Map<string, any>) => {
            // 選択肢が指定されている場合は、それのみに限定する
            if (this.optionInclude) {
              const optionItem = (option.get(this.optionCode) as Array<any>);
              this.options = optionItem.filter((item) => {
                return this.optionInclude.includes(item.value);
              });
            } else {
              this.options = option.get(this.optionCode);
            }
          }
        )
      );
      this.options = this.pageUtilService.optionSubject
        .getValue()
        .get(this.optionCode);
    }

    const validator = this.formControl.validator
      ? this.formControl.validator({} as AbstractControl)
      : null;
    if (validator && validator.required) {
      this.required = true;
    }
  }
  onSelectedChange(event: any) {
    this.selectedChange.emit({
      type: "selectedChange",
      from: this.controlName,
      value: event,
    });
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get formControl() {
    const formControl = this.parent.get(this.controlName);
    // console.log(formControl);
    return formControl;
  }

  /**
   * ステータスチェック
   */
  checkFormControlStatus(formControl: AbstractControl) {
    return formControl.dirty
      ? formControl.invalid
        ? "danger"
        : "success"
      : "basic";
  }
}
