import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService, } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject, Observable } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from "app/jb/service/common/auth.service";
import { LoginService } from "app/jb/service/login/login.service";
import { OrderService } from "app/jb/service/order/order.service";

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  private destroy$: Subject<void> = new Subject<void>();
  public readonly materialTheme$: Observable<boolean>;
  userPictureOnly: boolean = false;
  user: any;

  userMenu = [ { title: 'Profile' }, { title: 'Log out' } ];
  disabled: boolean = false;

  public constructor(
    private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private layoutService: LayoutService,
    private router: Router,
    private authService: AuthService,
    private loginService: LoginService,
    private orderService: OrderService,
    
  ) {
    this.materialTheme$ = this.themeService.onThemeChange()
      .pipe(map(theme => {
        const themeName: string = theme?.name || '';
        return themeName.startsWith('material');
      }));
  }

  ngOnInit() {
    let userinfo = this.authService.getUserInfomation();
    if(userinfo.usertype == "2"){
      this.disabled = true;
    }
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  navigateOrder() {
    this.router.navigate(['./page/order']);
  }

  /**
   * ログアウトアイコンのクリックイベント
   */
  async onLogoutClick() {
    if (confirm('ログアウトしますか？')) {
      //カートの中身を削除する処理
      await this.orderDelete();
      let param = {
        identityid: this.authService.getTokenObj().identityId
      };
      //サインアウトAPI呼出し
      await this.loginService.logout(param)
        .then(async (res: any) => {
          //セッションストレージの内容を破棄
          this.authService.clearAuthentication();
          //ログアウト画面へ遷移
          this.router.navigate(['']);
        })
        .catch(async (err: any) => {
          //セッションストレージの内容を破棄
          this.authService.clearAuthentication();
        });
    }    
  }

  makerChage() {
    if (confirm('ハウスメーカー選択画面に戻ります。カートの中身が空になりますがよろしいですか？')) {
      //カートの中身を削除する処理
      this.orderDelete();
      let userinfo = this.authService.getUserInfomation();
      userinfo.makerid= "";
      userinfo.username= "";
      this.authService.setUserInfomation(userinfo);
      this.router.navigate(['./page/house-maker']);
    } 
  }

  //カートの中身を削除する処理
  async orderDelete(){
    let userinfo = this.authService.getUserInfomation();
    let param ={
      userid: userinfo.userid,
      mailaddress: userinfo.mailaddress,
    }
    await this.orderService.deleteall(param);
  }

}
