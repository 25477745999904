import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { PageUtilService } from "app/jb/service/common/page-util.service";
import { Subscription } from "rxjs";
import { ValueSharedService } from "app/jb/service/common/value-shared.service";

/**
 * パスワード変更完了画面
 * @author
 */
@Component({
  selector: "change-pass-success",
  templateUrl: "./change-pass-success.component.html",
  styleUrls: ["./change-pass-success.component.scss"],
})
export class ChangePassSuccessComponent implements OnInit, OnDestroy {

  subscription: Subscription;

  buttonFlg: boolean = true;

  constructor(
    private router: Router,
    private valueSharedService: ValueSharedService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    console.log(this.valueSharedService.isfirst)
    if (!this.valueSharedService.isfirst && !this.valueSharedService.isreset) {
      this.buttonFlg = false;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async onSubmit() {
    this.router.navigate(["/login"]);
  }

}