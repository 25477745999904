import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { PageUtilService } from "app/jb/service/common/page-util.service";
import { Subscription } from "rxjs";
import { AuthService } from "app/jb/service/common/auth.service";

import { UserListService } from "app/jb/service/user/user-list/user-list.service";
import { MakerModel } from "app/jb/model/maker/maker.model";

/**
 *  ハウスメーカー選択画面
 * @author
 */
@Component({
  selector: "ngx-house-maker",
  templateUrl: "./house-maker.component.html",
  styleUrls: ["./house-maker.component.scss"],
})
export class HouseMakerComponent implements OnInit, OnDestroy {
  makerSelectForm: FormGroup;
  appLayout: any;

  loading: boolean;
  messages: any;
  subscription: Subscription;

  // プルダウンメニュー、ラジオボタン用オプションデータ
  selectMaker = [];
  makerlist: MakerModel[];

  userInfo:any;


  constructor(
    private router: Router,
    private pageUtilService: PageUtilService,
    private userListService: UserListService,
    private authService: AuthService,
  ) {
    this.loading = false;
    this.messages = pageUtilService.messages;
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.createOptions();
    this.createAppLayout(this);
    this.makerSelectForm = this.pageUtilService.createForm(this.appLayout);
  }

  ngOnDestroy(): void {
    this.pageUtilService.clearSubjects();
    this.subscription.unsubscribe();
  }

  /**
   * プルダウンメニュー用オプションの作成
   */
   createOptions() {
    let param = {
      mailaddress: this.authService.getUserInfomation().mailaddress
    }
    this.userListService.getList(param)
      .subscribe((list) => {
        list.forEach((row) => {
          let userinfo = {
            userid: row.userid,
            mailaddress: row.mailaddress,
            usertype: row.usertype,
            makerid: row.makerid,
            customerid: row.customerid,
            customertype: row.customertype,
            username: row.username,
            approvalfunction: row.approvalfunction,
            approvalid: row.approvalid,
            approvalflg: row.approvalflg,
          };
          this.selectMaker.push(
            { value: userinfo, label: row.makername },
          );
        });
      },
    )
  };

  // 子コンポーネントのイベントハンドリング
  onChildEvent(event: any) {
    if (event.type === "selectedChange") {
      if (event.from === "maker") {
        this.userInfo = event.value
        // ボタンの非活性を解除するにする
        this.appLayout.cardList.forEach((card) => {
          card.rows.forEach((row) => {
            row.cols.map((col) => {
              if ("successButton" === col.controlName) {
                col.disabled = false;
              }
            });
          });
        });
      } 
    }
    else if (event.type === "buttonClick") {
      if (event.from === "successButton") {
        this.onSubmit();
      } 
    }
  }

  async onSubmit() {
    //ユーザー情報をセッションストレージに格納
    this.authService.setUserInfomation(this.userInfo);
    this.router.navigate(["/home"]);
  }    

  createAppLayout(_this) {
    this.appLayout = {
      cardList: [
        {
          name: "ハウスメーカー選択",
          width: 12,
          status: "info",
          rows: [
            {
              cols: [
                { width: 2,},
                { type: "select", label: "ハウスメーカー", controlName: "maker", width: 8, required: true, options: this.selectMaker, },
              ],
            },
            {
              cols: [
                { width: 5,},
                { type: "button",label: "選択",controlName: "successButton",status: "info", disabled: "true"},
              ],
            },
          ]
        },
      ],
    };
  }
}
