import { ApiExecuteService } from "app/jb/service/common/apiExecute.service";
import { ApiExecuteModel } from "app/jb/model/api/apiExecute.model";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { from, Observable, of } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class LoginService {
  // api setting
  loginApiParams = {
    uri: "/signin",
    method: "POST",
    needsAuth: false,
    no: "001",
    description: "ログイン処理",
  };

  logoutApiParams = {
    uri: "/signout",
    method: "POST",
    needsAuth: true,
    no: "002",
    description: "ログアウト処理",
  };

  constructor(private apiExecuteService: ApiExecuteService) {
    
  }

  login(
    param: any
  ): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.loginApiParams.needsAuth;
    apiExecuteModel.method = this.loginApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.loginApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    console.log(apiExecuteModel);
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }

  logout(
    param: any
  ): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.logoutApiParams.needsAuth;
    apiExecuteModel.method = this.logoutApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.logoutApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    console.log(apiExecuteModel);
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }

}
