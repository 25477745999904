import { Injectable } from '@angular/core';
import * as AWS from 'aws-sdk/global';

import { TokenObjModel } from 'app/jb/model/tokenObj.model';
import { AuthUserInfoModel } from 'app/jb/model/common/authUserInfo.model';
import { environment } from 'environments/environment';

/**
 * 認証サービス
 */
@Injectable({
  providedIn: 'root',
})
export class AuthService {

  constructor() { }

  authUserInfoModel :AuthUserInfoModel; // ユーザー共通情報


  // TODO ほかのセッションストレージに保管してる項目どうしようかな
  
  /**
   * ログイン済かどうかを返却する
   *   xapptokenが保管されているか否かで判定する
   * 判定処理は呼び出し元で実施すること。
   */
  public getStatusIsAuthenticated(): boolean {
     return this.getXAppToken() ? true : false;
  }

  /**
   * AWS認証情報を最新化する
   * @param TokenObjModel
   * @param boolean ログイン後初回の場合true / それ以外の場合false
   */
  public refreshCredentials(tokenObj: TokenObjModel, isFirst: boolean){
    const self = this;
    return environment.type === 'mock' ?
    new Promise(function(resolve){
      self.setCredentials({
        accessKeyId: 'accessKeyId',
        secretAccessKey: 'secretAccessKey',
        sessionToken: 'sessionToken'
      });
      resolve();
    }) :
    new Promise(function(resolve, reject){

      // 初回は生成を実施する
      if(isFirst){
        AWS.config.region = environment.aws.region;
        AWS.config.credentials = <AWS.CognitoIdentityCredentials> new AWS.CognitoIdentityCredentials({
          IdentityPoolId: tokenObj.identityPoolId,
          IdentityId:tokenObj.identityId,
          Logins: {
            'cognito-identity.amazonaws.com' : tokenObj.token,
          },
        });
        self.setCredentials(AWS.config.credentials);
        // 認証情報の取得
        return (<AWS.CognitoIdentityCredentials>AWS.config.credentials).get((err) => {
          if (err) {
            console.error(err);
            console.log("refreshCredentials error! ");
            reject("ログイン処理に失敗しました。認証情報が取得できません。");
            return;
          } else {
            self.setCredentials(AWS.config.credentials);
            resolve();
          }
        });
      }
      // 2回目以降は更新を実施
      else {
        AWS.config.region = environment.aws.region;
        if (!AWS.config.credentials) {
          AWS.config.credentials = <AWS.CognitoIdentityCredentials> new AWS.CognitoIdentityCredentials({
            IdentityPoolId: tokenObj.identityPoolId,
            IdentityId:tokenObj.identityId,
            Logins: {
              'cognito-identity.amazonaws.com' : tokenObj.token
            },
          });
          self.setCredentials(AWS.config.credentials);
        } else {
          let credentials = self.getCredentials();
          AWS.config.credentials.accessKeyId = credentials.accessKeyId;
          AWS.config.credentials.secretAccessKey = credentials.secretAccessKey;
          AWS.config.credentials.sessionToken = credentials.sessionToken;

        }
        // refreshの必要性判定を実施してからrefreshする
        if((<AWS.CognitoIdentityCredentials>AWS.config.credentials).needsRefresh()){
          return (<AWS.CognitoIdentityCredentials>AWS.config.credentials).refresh((err) => {
            if (err) {
              console.error(err);
              console.log("refreshCredentials error! ");
              reject("token更新処理に失敗しました。");
              return;
            } else {
              // 取得した3つを格納
              self.setCredentials(AWS.config.credentials);
              resolve();
            }
          })
        }
        //更新の必要がない場合はそのまま
        resolve();
      }
    }
  )}

  public setTokenObj(tokenObj: TokenObjModel) {
    sessionStorage.setItem("tokenobj", JSON.stringify(tokenObj));
  }

  public getTokenObj() {
    return JSON.parse(sessionStorage.getItem("tokenobj"));
  }

  private setCredentials(credentials: any) {
    sessionStorage.setItem("accesskey", credentials.accessKeyId);
    sessionStorage.setItem("secretkey", credentials.secretAccessKey);
    sessionStorage.setItem("sessiontoken", credentials.sessionToken);
  }

  public getCredentials() {
    return {
      accessKeyId: sessionStorage.getItem("accesskey"),
      secretAccessKey: sessionStorage.getItem("secretkey"),
      sessionToken: sessionStorage.getItem("sessiontoken")
    }
  }

  public setXAppToken(xapptoken: string){
    sessionStorage.setItem("xapptoken", xapptoken);
  }

  public getXAppToken() {
    return sessionStorage.getItem("xapptoken");
  }

  public setApiFqdn(apifqdn: string){
    sessionStorage.setItem("apifqdn", apifqdn);
  }

  public getApiFqdn(){
    return sessionStorage.getItem("apifqdn");
  }

  public getLatestCredentials() {
    return this.refreshCredentials(null, false);
  }

  // /**
  //  * ユーザー権限情報をセッションストレージから取得します
  //  * @returns ユーザー権限情報のJSONオブジェクト
  //  */
  //  public getUserRoles(){
  //   return JSON.parse(localStorage.getItem('lwos-userroles'));
  // }

  // /**
  //  * ユーザー権限情報をセッションストレージに格納します
  //  * @param userRoles ユーザー権限情報のJSONオブジェクト
  //  */
  // public setUserRoles(userRoles: any){
  //   localStorage.setItem('lwos-userroles', JSON.stringify(userRoles));
  // }

  /**
   * ユーザーアカウント情報をセッションストレージから取得します
   * @returns ユーザーアカウント情報のJSONオブジェクト
   */
  public getUserInfomation(){
    return JSON.parse(localStorage.getItem('lwos-userinfo'));
  }

  /**
   * ユーザーアカウント情報をセッションストレージに格納します
   * @param userInfo ユーザーアカウント情報のJSONオブジェクト
   */
   public setUserInfomation(userInfo: any){
    localStorage.setItem('lwos-userinfo', JSON.stringify(userInfo));
  }

  /**
   * 注文検索情報をセッションストレージから取得します
   * @returns 注文検索情報のJSONオブジェクト
   */
   public getOrdersearch(){
    return JSON.parse(localStorage.getItem('lwos-ordersearch'));
  }

  /**
   * 注文検索情報をセッションストレージに格納します
   * @param ordersearch 注文検索情報のJSONオブジェクト
   */
   public setOrdersearch(ordersearch: any){
    localStorage.setItem('lwos-ordersearch', JSON.stringify(ordersearch));
  }

  /**
   * 注文検索情報(ロンシール管理者)をセッションストレージから取得します
   * @returns 注文検索情報のJSONオブジェクト
   */
   public getOrdersearchLonseal(){
    return JSON.parse(localStorage.getItem('lwos-ordersearchLonseal'));
  }

  /**
   * 注文検索情報(ロンシール管理者)をセッションストレージに格納します
   * @param ordersearchLonseal 注文検索情報のJSONオブジェクト
   */
   public setOrdersearchLonseal(ordersearchLonseal: any){
    localStorage.setItem('lwos-ordersearchLonseal', JSON.stringify(ordersearchLonseal));
  }

  /**
   * 注文検索情報(承認者用)をセッションストレージから取得します
   * @returns 注文検索情報のJSONオブジェクト
   */
   public getOrdersearchApproval(){
    return JSON.parse(localStorage.getItem('lwos-ordersearchApproval'));
  }

  /**
   * 注文検索情報(承認者用)をセッションストレージに格納します
   * @param ordersearchApproval 注文検索情報のJSONオブジェクト
   */
   public setOrdersearchApproval(ordersearchApproval: any){
    localStorage.setItem('lwos-ordersearchApproval', JSON.stringify(ordersearchApproval));
  }

  /**
   * 注文者情報をセッションストレージから取得します
   * @returns 注文者情報のJSONオブジェクト
   */
   public getOrderhead(){
    return JSON.parse(localStorage.getItem('lwos-orderhead'));
  }

  /**
   * 注文者情報をセッションストレージに格納します
   * @param orderhead 注文者情報のJSONオブジェクト
   */
   public setOrderhead(orderhead: any){
    localStorage.setItem('lwos-orderhead', JSON.stringify(orderhead));
  }

  // public setAuthUserInfo(authUserInfo: AuthUserInfoModel) {
  //   sessionStorage.setItem("authUserid", authUserInfo.userid ? authUserInfo.userid : "");
  //   sessionStorage.setItem("authAuthorization", authUserInfo.authorization ? authUserInfo.authorization : "");
  // }

  // public getAuthUserInfo() :AuthUserInfoModel{
  //   return {
  //     userid: sessionStorage.getItem("authUserid"),
  //     roomcd: "",
  //     authorization: sessionStorage.getItem("authAuthorization"),
  //   };
  // }

  /**
   * 認証関連情報をクリアする
   */
  public clearAuthentication(){
    localStorage.removeItem("xapptoken");
    localStorage.removeItem("apifqdn");
    localStorage.removeItem("tokenobj");
    localStorage.removeItem("accesskey");
    localStorage.removeItem("secretkey");
    localStorage.removeItem("sessiontoken");
    localStorage.removeItem("lwos-userinfo");

    //各検索項目の条件をクリア
    localStorage.removeItem("lwos-ordersearch");
    localStorage.removeItem("lwos-ordersearchLonseal");
    localStorage.removeItem("lwos-ordersearchApproval");
    
    
    AWS.config.credentials = null;
  }

}
