import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, FormGroup, ValidationErrors } from "@angular/forms";

/**
 * ファイルアップロード
 * @author J32200
 */
@Component({
  selector: "jb-form-file",
  templateUrl: "./jb-form-file.component.html",
  styleUrls: ["./jb-form-file.component.scss"],
})
export class JbFormFileComponent implements OnInit {
  @Input() parent: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() fieldSize: string = "medium";
  @Input() placeholder: string = "";
  @Input() accept: string = ".pdf";
  @Input() messages: any;

  required: boolean;
  validator: ValidationErrors;
  constructor() {}

  ngOnInit() {
    // 必須
    this.validator = this.formControl.validator
      ? this.formControl.validator({} as AbstractControl)
      : null;
    if (this.validator && this.validator.required) {
      this.required = true;
    }
  }

  get formControl() {
    const formControl = this.parent.get(this.controlName);
    return formControl;
  }

  onChange(event: any) {
    const file = event.target.files[0];
    if (file) {
      let fileReader: FileReader = new FileReader();
      fileReader.onload = (ev) => {
        this.formControl.setValue({
          file: file,
          data: ev.target.result,
        });
      };
      // DataUrlを取得する
      // @see https://developer.mozilla.org/ja/docs/Web/API/FileReader/readAsDataURL
      fileReader.readAsDataURL(file);
    } else {
      if (this.validator && this.validator.required) {
        this.formControl.setErrors({
          required: true,
        });
      }
    }
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
  }

  /**
   * ステータスチェック
   */
  checkFormControlStatus(formControl: AbstractControl) {
    return formControl.dirty
      ? formControl.invalid
        ? "danger"
        : "success"
      : "basic";
  }
}
