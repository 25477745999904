import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from "@angular/common/http";
import { Observable, EMPTY } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { ErrorModel } from "app/jb/model/common/error.model";
import { ErrorService } from "app/jb/service/common/error.service";
import { environment } from "environments/environment";

@Injectable()
export class XapptokenInterceptor implements HttpInterceptor {
  constructor(private router: Router, private errorService: ErrorService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status == 403) {
          if (error.url.indexOf(environment.api.apiBase) != -1) {
            console.log("xapptoken error");
            let errorJson = new ErrorModel();
            errorJson.dispname = "エラー";
            errorJson.operation = "ログイン期限の確認";
            errorJson.message =
              "ログイン期限が切れました。再度ログインを行ってください。";

            this.errorService.errorJson = errorJson;
            this.router.navigate(["login"]);
          }
          throw error;
        } else {
          throw error;
        }
      })
    );
  }
}
