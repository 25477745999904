import { AbstractControl, FormGroup } from "@angular/forms";
import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "jb-form-datepicker",
  templateUrl: "./jb-form-datepicker.component.html",
  styleUrls: ["./jb-form-datepicker.component.scss"],
})
export class JbFormDatepickerComponent implements OnInit {
  @Input() parent: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() placeholder: string = "";
  @Input() readonly = false;
  @Input() fieldSize: string = "medium";
  @Input() messages: any;

  required: boolean;

  constructor() {}

  ngOnInit(): void {
    // this.parent.get(this.controlName).setValue(new Date());
    const validator = this.formControl.validator
      ? this.formControl.validator({} as AbstractControl)
      : null;
    if (validator && validator.required) {
      this.required = true;
    }
  }

  get formControl() {
    const formControl = this.parent.get(this.controlName);
    // console.log(formControl);
    return formControl;
  }

  /**
   * ステータスチェック
   */
  checkFormControlStatus(formControl: AbstractControl) {
    return formControl.dirty
      ? formControl.invalid
        ? "danger"
        : "success"
      : "basic";
  }
}
