<div class="row">
  <form
    class="col-xl-12"
    [formGroup]="changePassForm"
  >
    <div class="row">
      <div class="col-xl-6 offset-xl-3">
        <jb-form-auto-layout
          [parent]="changePassForm"
          [appLayout]="appLayout"
          [loading]="loading"
          [messages]="messages"
          fieldSize="small"
          (childEvent)="onChildEvent($event)"
        ></jb-form-auto-layout>
      </div>
    </div>
  </form>
</div>
