<div [formGroup]="parent">
  <label [for]="controlName" class="label"
    >{{ label
    }}<span *ngIf="required" class="required-mark">&nbsp;*</span></label
  >
  <!-- <label> -->
  <!-- <nb-icon icon="upload-outline" pack="eva"> </nb-icon> -->
  <input
    type="file"
    nbInput
    fullWidth
    [name]="controlName"
    [fieldSize]="fieldSize"
    [placeholder]="placeholder"
    [accept]="accept"
    [status]="checkFormControlStatus(formControl)"
    (change)="onChange($event)"
  />
  <!-- </label> -->
  <ng-container *ngIf="formControl.invalid && formControl.touched">
    <p class="caption status-danger" *ngIf="formControl.errors?.required">
      {{ messages.required }}
    </p>
    <ng-container *ngIf="formControl.errors?.apiError">
      <p
        class="caption status-danger"
        *ngFor="let apiError of formControl.errors.apiError"
      >
        {{ apiError.code + ": " + apiError.message }}
      </p>
    </ng-container>
  </ng-container>
</div>
