import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { PageUtilService } from "app/jb/service/common/page-util.service";
import { Subscription } from "rxjs";
import { ValueSharedService } from "app/jb/service/common/value-shared.service";

/**
 * パスワード変更完了画面
 * @author
 */
@Component({
  selector: "change-pass-success2",
  templateUrl: "./change-pass-success2.component.html",
  styleUrls: ["./change-pass-success2.component.scss"],
})
export class ChangePassSuccess2Component implements OnInit, OnDestroy {

  subscription: Subscription;

  buttonFlg: boolean = true;

  constructor(
    private router: Router,
    private valueSharedService: ValueSharedService,
  ) {
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    console.log(this.valueSharedService.isfirst);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  async onSubmit() {
    this.router.navigate(["/login"]);
  }

}