import { Injectable } from '@angular/core';
import { ApigSigV4Service } from 'app/jb/service/common/apigSigv4.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ApiResultJsonModel } from 'app/jb/model/api/apiResultJson.model';
import { ApiExecuteModel } from 'app/jb/model/api/apiExecute.model';

import { AuthService } from 'app/jb/service/common/auth.service';
import { environment } from 'environments/environment';

/**
 * API実行共通処理。非APIGatewayであってもこの処理経由で実行すること
 */
@Injectable({
  providedIn: 'root'
})
export class ApiExecuteService {

  // TODO 定数ファイルから取得してもいいかもしれん
  xAppTokenHeaderKey = 'x-apptoken';

  constructor(
    private apigSigV4Service: ApigSigV4Service,
    private authService: AuthService,
    private http: HttpClient,
  ) {
  }

  /**
   * API実行共通処理
   * @param ApiExecuteModel
   * @return ApiResultJsonModel or null(GET/POST/PUT/DELETE以外を指定した場合)
   */
  public executeApi(apiExecuteModel: ApiExecuteModel):Promise<ApiResultJsonModel> {

    let self = this;

    // パラメータの取り出し
    const isNeedsSigv4 = ((environment.type === 'prod' || environment.type === 'stg'|| environment.type === 'dev') && apiExecuteModel.isNeedsSigv4) || false;
    // const isNeedsSigv4 = apiExecuteModel.isNeedsSigv4;
    const method = apiExecuteModel.method;
    const path = apiExecuteModel.path || "/";
    const queryParams = apiExecuteModel.queryParams;
    const headers = apiExecuteModel.headers || {};
    const body = apiExecuteModel.body;
    console.log(isNeedsSigv4);

    // IAM認証APIの場合、SigV4署名のheaderを算出する
    let header;
    let url;
    if (isNeedsSigv4) {
      // x-apptokenをヘッダーに追加する
      if (headers[self.xAppTokenHeaderKey] === undefined) {
        headers[self.xAppTokenHeaderKey] = self.authService.getXAppToken();
      }
      // sigv4の計算
      return self.apigSigV4Service.makeRequest(method, path, queryParams, headers, body)
      .then(res => {
        header = res;
        url = self.authService.getApiFqdn() + path;
        switch (method) {
          case "GET":
            return(self.executeGet(url, header, queryParams).toPromise());
          case "POST":
            return(self.executePost(url, header, queryParams, body).toPromise());
          case "PUT":
            return(self.executePut(url, header, queryParams, body).toPromise());
          case "DELETE":
            return(self.executeDelete(url, header, queryParams).toPromise());
          default: //上記4つ以外のMETHODはサポートしていない。自力で実装すること。
            return null;
        }
      })
      .catch(err => {
        throw(err);
      });
      // sigv4の計算をしない場合
    } else {
      header = headers;
      url = environment.api.nonAuthUrlBase + path;
      // 対応するMETHODに応じHTTPリクエストを実行する
      switch (method) {
        case "GET":
          return(self.executeGet(url, header, queryParams).toPromise());
        case "POST":
          return(self.executePost(url, header, queryParams, body).toPromise());
        case "PUT":
          return(self.executePut(url, header, queryParams, body).toPromise());
        case "DELETE":
          return(self.executeDelete(url, header, queryParams).toPromise());
        default: //上記4つ以外のMETHODはサポートしていない。自力で実装すること。
          return null;
      }

    }

  }

  /**
   * S3に署名付きURLでファイルオブジェクトをPUTします。
   * @param signedUrl 署名付きURL
   * @param file ファイルオブジェクト
   * @returns Promise
   */
   public s3PutObject(signedUrl: string, file: File): Promise<any> {
    let options = {
      headers: new HttpHeaders({
        'Content-Type': file.type
      })
    }
    return this.http.put<any>(signedUrl, file, options).toPromise();
  }

  /**
   * S3からファイルデータをblobでGETします
   * @param signedUrl 署名付きURL
   * @returns Promise
   */
  public s3GetBlob(signedUrl: string): Promise<any> {
    return this.http.get(signedUrl, { responseType: 'blob' }).toPromise();
  }

  /**
   * GET実行
   * @param url ex: https://xxxx.execute-api.ap-northeast-1.hogehoge/restapi/users/sugimon0213
   * @param header
   * @param queryParams
   */
  private executeGet(url: string, header: any, queryParams: any) {
    let options = {
      headers: new HttpHeaders(header)
    }

    // TODO今後実施。クエリパラメータに関しては[aaa=bbb&ccc=ddd]のStringでもらい、それをparseしてセットする
    if (queryParams) {
      options["params"] = queryParams;
    }
    console.log(queryParams);

    // API実行
    return this.http.get<ApiResultJsonModel>(url, options);
  }

  /**
   * POST実行
   * @param url ex: https://xxxx.execute-api.ap-northeast-1.hogehoge/restapi/users/sugimon0213
   * @param header
   * @param queryParams
   * @param body
   */
  // private executePost(url: string, header: any, queryParams: any, body: object){
  private executePost(url: string, header: any, queryParams: any, body: any) {
    let options = {
      headers: new HttpHeaders(header)
    }

    // TODO今後実施。クエリパラメータに関しては[aaa=bbb&ccc=ddd]のStringでもらい、それをparseしてセットする
    if (queryParams) {
      options["params"] = queryParams;
    }

    // API実行
    return this.http.post<ApiResultJsonModel>(url, JSON.stringify(body), options);
  }

  /**
   * PUT実行
   * @param url ex: https://xxxx.execute-api.ap-northeast-1.hogehoge/restapi/users/sugimon0213
   * @param header
   * @param queryParams
   * @param body
   */
  private executePut(url: string, header: any, queryParams: any, body: any){
    let options ={
      headers: new HttpHeaders(header)
    }

    // TODO今後実施。クエリパラメータに関しては[aaa=bbb&ccc=ddd]のStringでもらい、それをparseしてセットする
    if (queryParams) {
      options["params"] = queryParams;
    }

    // API実行
    return this.http.put<ApiResultJsonModel>(url, JSON.stringify(body), options);
  }

  /**
   * DELETE実行
   * @param url ex: https://xxxx.execute-api.ap-northeast-1.hogehoge/restapi/users/sugimon0213
   * @param header
   * @param queryParams
   */
  private executeDelete(url: string, header: any, queryParams: any) {
    let options = {
      headers: new HttpHeaders(header)
    }

    // TODO今後実施。クエリパラメータに関しては[aaa=bbb&ccc=ddd]のStringでもらい、それをparseしてセットする
    if (queryParams) {
      options["params"] = queryParams;
    }

    // API実行
    return this.http.delete<ApiResultJsonModel>(url, options);
  }

}
