import { AbstractControl, FormGroup } from "@angular/forms";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "jb-form-check",
  templateUrl: "./jb-form-check.component.html",
  styleUrls: ["./jb-form-check.component.scss"],
})
export class JbFormCheckComponent implements OnInit {
  @Input() parent: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() checkLabel: string;
  @Input() readonly = false;
  @Input() fieldSize: string = "medium";
  @Input() messages: any;
  @Output() change: EventEmitter<any>;

  required: boolean;

  constructor() {
    this.change = new EventEmitter<any>();

  }

  ngOnInit(): void {
    const validator = this.formControl.validator
      ? this.formControl.validator({} as AbstractControl)
      : null;
    if (validator && validator.required) {
      this.required = true;
    }
  }

  get formControl() {
    const formControl = this.parent.get(this.controlName);
    return formControl;
  }

  onChange(event: any) {
    this.change.emit({
      type: "change",
      from: this.controlName,
      value: event.target.checked,
    });
  }


  /**
   * ステータスチェック
   */
  checkFormControlStatus(formControl: AbstractControl) {
    return formControl.dirty
      ? formControl.invalid
        ? "danger"
        : "success"
      : "basic";
  }
}
