<!-- @jbcc -->
<!-- 不要コンポーネント削除 -->
<div class="header-container">
  <div class="logo-container">
    <a
      href="#"
      class="sidebar-toggle"
      matRipple
      [matRippleUnbounded]="true"
      [matRippleCentered]="true"
      (click)="toggleSidebar()"
    >
      <nb-icon
        [icon]="(materialTheme$ | async) ? 'menu-outline' : 'menu-2-outline'"
      ></nb-icon>
    </a>
  </div>
</div>

<div class="header-container">
    <nb-actions class="pc-container">
      <nb-action icon="shopping-cart" (click)="navigateOrder()"></nb-action>
    </nb-actions>
    
    <button class="pc-container" nbButton (click)="makerChage()" [disabled]="disabled"><nb-icon icon="home"></nb-icon>ハウスメーカー選択</button>

    <nb-actions class="pc-container" size="small">
      <nb-action icon="power-outline" (click)="onLogoutClick()"></nb-action>
    </nb-actions>
</div>
