<div [formGroup]="parent">
  <label [for]="controlName" class="label"
    >{{ label
    }}<span *ngIf="required" class="required-mark">&nbsp;*</span></label
  >
  <p>
    <nb-form-field>
      <nb-icon nbPrefix icon="calendar-outline" pack="eva"></nb-icon>
      <input
        nbInput
        fullWidth
        [placeholder]="placeholder"
        [nbDatepicker]="datepicker"
        [formControlName]="controlName"
        [status]="checkFormControlStatus(formControl)"
        readonly="readonly"
      />
    </nb-form-field>
    <nb-datepicker #datepicker></nb-datepicker>
  </p>
  <ng-container *ngIf="formControl.invalid && formControl.touched">
    <p class="caption status-danger" *ngIf="formControl.errors?.required">
      {{ messages.required }}
    </p>
    <ng-container *ngIf="formControl.errors?.apiError">
      <p
        class="caption status-danger"
        *ngFor="let apiError of formControl.errors.apiError"
      >
        {{ apiError.code + ": " + apiError.message }}
      </p>
    </ng-container>
  </ng-container>
</div>
