import { ValidatorFn, AbstractControl, FormGroup } from '@angular/forms';

/**
 * @classdesc 比較バリデータークラス
 * @author tomonari yamada
 */
export class CompareValidator {
    /**
     * 指定したコンポーネントと値を比較する
     * （値がセットされていない場合は比較しない）
     * @param compareControl 比較対象のコンポーネント
     * @param message エラー時のメッセージをセット
     * @returns 値を比較して異なる場合バリデーションエラー
     *
     * @example <caption>sample-component.ts</caption>
     * //バリデータークラスのインポート
     * import { CompareValidator } from 'app/jb/validator/compare.validator';
     *
     * //FormGroupの定義
     * this.commonService.fb.group({
     *   password: [{ value: '', disabled: this.isdisabled },
     *      CompareValidator.compare(this.formgroup.controls.passwordconfirmation, '確認用パスワードが一致しません'),
     *      CompareValidator.check(this.formgroup.controls.passwordconfirmation, 'パスワードおよびパスワード確認は同一の値を設定してください')],
     *   passwordconfirmation: [{ value: '', disabled: this.isdisabled },
     *      CompareValidator.compare(this.formgroup.controls.password, '確認用パスワードが一致しません'),
     *      CompareValidator.check(this.formgroup.controls.password, 'パスワードおよびパスワード確認は同一の値を設定してください')],
     * });
     */
    public static compare(compareControl: AbstractControl): ValidatorFn {
        return (control: FormGroup): { [key: string]: any } => {
            if (control?.value != null && control.value != ''
                && compareControl?.value != null && compareControl.value != '') {
                if (compareControl?.hasError('validateCompare')) {
                    delete compareControl.errors.validateCompare;
                    compareControl.updateValueAndValidity();
                }
                if (control.value !== compareControl.value) {
                    return { validateCompare: true };
                }
            }
            return null;
        }
    }

    /**
     * 指定したコンポーネントが空かどうかチェックする
     * （両コンポーネントが空の場合はメッセージを消去する）
     * @param checkControl チェック対象のコンポーネント
     * @param message エラー時のメッセージをセット
     * @returns 空の場合バリデーションエラー
     *
     * @example <caption>sample-component.ts</caption>
     * //バリデータークラスのインポート
     * import { CompareValidator } from 'app/jb/validator/compare.validator';
     *
     * //FormGroupの定義
     * this.commonService.fb.group({
     *   password: [{ value: '', disabled: this.isdisabled },
     *      CompareValidator.compare(this.formgroup.controls.passwordconfirmation, '確認用パスワードが一致しません'),
     *      CompareValidator.check(this.formgroup.controls.passwordconfirmation, 'パスワードおよびパスワード確認は同一の値を設定してください')],
     *   passwordconfirmation: [{ value: '', disabled: this.isdisabled },
     *      CompareValidator.compare(this.formgroup.controls.password, '確認用パスワードが一致しません'),
     *      CompareValidator.check(this.formgroup.controls.password, 'パスワードおよびパスワード確認は同一の値を設定してください')],
     * });
     */
    public static check(checkControl: AbstractControl): ValidatorFn {
        return (control: FormGroup): { [key: string]: any } => {
            if ((control?.value != null && control.value != '' && (checkControl?.value == null || checkControl.value == ''))
                || ((control?.value == null || control.value == '') && checkControl?.value != null && checkControl.value != '')) { // 片方のみ入力されている場合
                return { validateCompare: true };
            }
            else if ((checkControl?.value == null || checkControl.value == '') && (control?.value == null || control.value == '')) { // 両方空の場合
                if (control?.errors?.validateCompare) {
                    delete control.errors.validateCompare;
                    control.updateValueAndValidity();
                }
                if (checkControl?.hasError('validateCompare')) {
                    delete checkControl.errors.validateCompare;
                    checkControl.updateValueAndValidity();
                }
            }
            return null;
        }
    }
}