import { ExtraOptions, RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";
import {
  NbAuthComponent,
  NbLoginComponent,
  NbLogoutComponent,
  NbRegisterComponent,
  NbRequestPasswordComponent,
  NbResetPasswordComponent,
} from "@nebular/auth";
import { LoginComponent } from "app/jb/component/page/login/login.component";

import { HouseMakerComponent } from "app/jb/component/page/house-maker/house-maker.component";

import { ResetPassMailComponent } from "app/jb/component/page/reset-pass-mail/reset-pass-mail.component";
import { ChangePassComponent } from "./jb/component/page/change-pass/change-pass.component";
import { ChangePassSuccessComponent } from "./jb/component/page/change-pass-success/change-pass-success.component";
import { ChangePass2Component } from "./jb/component/page/change-pass2/change-pass2.component";
import { ChangePassSuccess2Component } from "./jb/component/page/change-pass-success2/change-pass-success2.component";

export const routes: Routes = [
  {
    path: "",
    component: LoginComponent,
  },
  {
    path: "login",
    component: LoginComponent,
  },
  {
    path: "reset-pass-mail",
    component: ResetPassMailComponent,
  },
  {
    path: "change-pass",
    component: ChangePassComponent,
  },
  {
    path: "change-pass-success",
    component: ChangePassSuccessComponent,
  },
  {
    path: "change-pass2",
    component: ChangePass2Component,
  },
  {
    path: "change-pass-success2",
    component: ChangePassSuccess2Component,
  },
  {
    path: "page/house-maker",
    component: HouseMakerComponent,
  },
  {
    path: "auth",
    component: NbAuthComponent,

    children: [
      {
        path: "",
        component: NbLoginComponent,
      },
      {
        path: "register",
        component: NbRegisterComponent,
      },
      {
        path: "logout",
        component: NbLogoutComponent,
      },
      {
        path: "change-password",
        component: NbRequestPasswordComponent,
      },
      {
        path: "reset-password",
        component: NbResetPasswordComponent,
      },
    ],
  },
  /**
   * @jbcc
   * ROOTの向き先をPJ用フォルダに変更
   * 既存サンプル集のリソースパスをsamplesに変更
   * not foundの向き先をROOTに変更
   */
  {
    path: "pages",
    loadChildren: () =>
      import("./pages/pages.module").then((m) => m.PagesModule),
  },
  { path: "samples", redirectTo: "pages", pathMatch: "full" },
  {
    path: "",
    loadChildren: () => import("./jb/jb.module").then((m) => m.JBModule),
  },
  { path: "**", redirectTo: "" },
];

const config: ExtraOptions = {
  useHash: false,
};

@NgModule({
  imports: [RouterModule.forRoot(routes, config)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
