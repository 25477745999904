<div [formGroup]="parent">
  <nb-form-field>
    <label [for]="controlName" class="label"
      >{{ label
      }}<span *ngIf="required" class="required-mark">&nbsp;*</span></label
    >
    <input
      [type]="type"
      nbInput
      fullWidth
      [name]="controlName"
      [formControlName]="controlName"
      [fieldSize]="fieldSize"
      [placeholder]="placeholder"
      [maxlength]="maxlength"
      [status]="checkFormControlStatus(formControl)"
      (blur)="onBlur()"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [mask]="mask"
      [thousandSeparator]="thousandSeparator"
      [class.rightalign]="numberCommmaSeparate"
      [allowNegativeNumbers]="true"
      />
    <button
      type="button"
      *ngIf="mode && !formControl.disabled"
      nbSuffix
      nbButton
      ghost
      [size]="fieldSize"
    >
      <nb-icon icon="search-outline" pack="eva" (click)="onClickSearchIcon()">
      </nb-icon>
    </button>
  </nb-form-field>
  <ng-container *ngIf="formControl.invalid && formControl.touched">
    <p class="caption status-danger" *ngIf="formControl.errors?.required">
      {{ messages.required }}
    </p>
    <p class="caption status-danger" *ngIf="formControl.errors?.validateCompare">
      {{ messages.notmatch }}
    </p>
    <p class="caption status-danger" *ngIf="formControl.errors?.validatePassword">
      {{ messages.notpass }}
    </p>
    <ng-container *ngIf="formControl.errors?.apiError">
      <p
        class="caption status-danger"
        *ngFor="let apiError of formControl.errors.apiError"
      >
        {{ apiError.code + ": " + apiError.message }}
      </p>
    </ng-container>
  </ng-container>
</div>
