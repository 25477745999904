import { Injectable } from "@angular/core";

import { FormGroup } from "@angular/forms";
import { UserModel } from "app/jb/model/user/user.model";
import { InfoModel } from "app/jb/model/info/info.model";
import { MakerModel } from "app/jb/model/maker/maker.model";
import { SpecModel } from "app/jb/model/maker/spec.model";

@Injectable({
  providedIn: "root",
})
export class ValueSharedService {
  constructor() { }

  //お知らせ用
  info: InfoModel;
  infoid: number;
  fileid: number;
  infoDetailprocmode: string;

  //ユーザー用
  user: UserModel;
  userid: string;
  userdetailprocmode: string;
  isfirst: boolean;
  isreset: boolean;
  islimit: boolean;

  //お問い合わせ掲示板用
  questiondetailprocmode: string;
  contactid: string;

  //ハウスメーカー用
  maker: MakerModel;
  makerid: string;
  makerDetailprocmode: string;

  //仕様用
  spec: SpecModel;
  makerspecid: string;
  specDetailprocmode: string;

  //ユーザー検索子画面
  customerid: string;
  customertype: string;
  customername: string;
  username: string;

  //注文状況照会検索
  orderfrom: Date;
  orderto:Date;
  orderstatus: string;

  //注文状況照会詳細
  orderInfo: any;
  ordernumber: number;

}
