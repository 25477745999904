import { ApiExecuteService } from "app/jb/service/common/apiExecute.service";
import { ApiExecuteModel } from "app/jb/model/api/apiExecute.model";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { from, Observable, of } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";

import { UserModel } from "app/jb/model/user/user.model";

@Injectable({
  providedIn: "root",
})
export class UserListService {
  // api setting
  getListApiParams = {
    uri: "/user/list",
    method: "POST",
    needsAuth: true,
    no: "001",
    description: "ユーザーマスタの一覧取得処理",
  };

  updateApiParams = {
    uri: "/user/changevalid",
    method: "PUT",
    needsAuth: true,
    no: "003",
    description: "ユーザー論理削除区分処理",
  };

  constructor(private apiExecuteService: ApiExecuteService) {
    
  }

  getList(
    param: any
  ): Observable<Array<any>> {
    console.log(environment.type);

    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.getListApiParams.needsAuth;
    apiExecuteModel.method = this.getListApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.getListApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };
    console.log(apiExecuteModel);

    const self = this;
    return from(
      new Promise<Array<any>>((resolve, reject) => {
        this.apiExecuteService
          .executeApi(apiExecuteModel)
          .then((res) => {
            console.log(res);
            // 対象データが見つからない場合、error.code == "CODE404"になる。この場合はthrowしない
            console.log(res.data);
            if (res.data === null) {
              if ("CODE404" != res.error.code) {
                throw new HttpErrorResponse({
                  error: res.error,
                  status: 400,
                  statusText: "Bad Request",
                  url: self.getListApiParams.uri,
                });
              } else {
                // エラー情報が格納されている場合はエラーオブジェクトを返す
                reject({
                  error: res.error,
                  errorHeader: res.errorHeader,
                  errorDetail: res.errorDetail,
                });
              }
            } else {
              // 正常な結果が格納されている場合、メソッド仕様のデータ型変数を返す
              resolve(res.data.details as Array<any>);
            }
          })
          .catch((err) => {
            console.error(err);
            console.log(`Api execution failed: ${err.message}`);
            reject(err);
          });
      })
    );

    // return of(list);

    // throw new Error('Method not implemented.');
  }

  changeValid(param: any): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.updateApiParams.needsAuth;
    apiExecuteModel.method = this.updateApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.updateApiParams.uri + "/" + param.userid;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          // // 対象データが見つからない場合、error.code == "CODE404"になる。この場合はthrowしない
          // if (res.data === null) {
          //   if ("CODE404" != res.error.code) {
          //     throw new HttpErrorResponse({
          //       error: res.error,
          //       status: 400,
          //       statusText: "Bad Request",
          //       url: self.getInitDataApiParams.uri,
          //     });
          //   } else if (res.error) {
          //     // エラー情報が格納されている場合はエラーオブジェクトを返す
          //     reject(res);
          //   }
          // } else {
          //   // 正常な結果が格納されている場合、メソッド仕様のデータ型変数を返す
          //   resolve(res.data);
          // }
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }
}
