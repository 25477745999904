import { Subscription } from "rxjs";
import { PageUtilService } from "app/jb/service/common/page-util.service";
import { AbstractControl, FormGroup } from "@angular/forms";
import { Component,  EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";

@Component({
  selector: "jb-form-radio",
  templateUrl: "./jb-form-radio.component.html",
  styleUrls: ["./jb-form-radio.component.scss"],
})
export class JbFormRadioComponent implements OnInit, OnDestroy {
  @Input() parent: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() optionCode: string;
  @Input() options: any;
  @Input() readonly = false;
  @Input() fieldSize: string = "medium";
  @Input() messages: any;
  @Output() valueChange: EventEmitter<any>;

  subscription: Subscription;
  required: boolean;

  constructor(private pageUtilService: PageUtilService) {
    this.subscription = new Subscription();
    this.valueChange = new EventEmitter<any>();
  }

  ngOnInit(): void {
    // optionsが直接指定されていたら監視しない
    if (!this.options) {
      this.subscription.add(
        this.pageUtilService.optionSubject.subscribe(
          (options: Map<string, any>) => {
            this.options = options.get(this.optionCode);
            // ラジオボタンは未選択は削除する
            this.options = this.options?.filter((opt, i) => i !== 0);
          }
        )
      );
      this.options = this.pageUtilService.optionSubject
        .getValue()
        .get(this.optionCode);
    }

    // 必須判定
    const validator = this.formControl.validator
      ? this.formControl.validator({} as AbstractControl)
      : null;
    if (validator && validator.required) {
      this.required = true;
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get formControl() {
    const formControl = this.parent.get(this.controlName);
    return formControl;
  }

  onChange(event: any) {
    this.valueChange.emit({
      type: "radioChange",
      from: this.controlName,
      value: event,
    });
  }

  /**
   * ステータスチェック
   */
  checkFormControlStatus(formControl: AbstractControl) {
    return formControl.dirty
      ? formControl.invalid
        ? "danger"
        : "success"
      : "basic";
  }
}
