import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { PageUtilService } from "app/jb/service/common/page-util.service";
import { Subscription } from "rxjs";
import { environment } from "environments/environment";
import { PasswordService } from "app/jb/service/password/password.service";
import { NbGlobalPhysicalPosition } from "@nebular/theme";

/**
 * 機能名: パスワード再設定用メール送信
 * 機能種別: 更新
 * @author
 */
@Component({
  selector: "ngx-reset-pass-mail",
  templateUrl: "./reset-pass-mail.component.html",
  styleUrls: ["./reset-pass-mail.component.scss"],
})
export class ResetPassMailComponent implements OnInit, OnDestroy {
  resetPassMailForm: FormGroup;
  appLayout: any;

  loading: boolean;
  messages: any;
  subscription: Subscription;

  constructor(
    private router: Router,
    private pageUtilService: PageUtilService,
    private passwordService: PasswordService,
    private fb: FormBuilder,
  ) {
    this.loading = false;
    this.messages = pageUtilService.messages;
    this.subscription = new Subscription();
  }

  ngOnInit(): void {
    this.createAppLayout(this);
    // this.resetPassMailForm = this.pageUtilService.createForm(this.appLayout);
    this.resetPassMailForm = this.fb.group({
      mailaddress: [{ value: '', disabled: false }, [Validators.required]]
    });
    this.createAppLayout(this);
  }

  ngOnDestroy(): void {
    // this.pageUtilService.clearSubjects();
    // this.subscription.unsubscribe();
  }

  // 子コンポーネントのイベントハンドリング
  onChildEvent(event: any) {
    console.log(event);
    // ボタン
    if (event.type === "buttonClick") {
      this.onButtonClick(event);
    }
  }
  onButtonClick(event: any) {

    if (event.from === "changePasswordButton") {
      if (this.resetPassMailForm.valid) { 
        this.loading = true;
        const param = this.resetPassMailForm.getRawValue();
        console.log(param);
        this.passwordService
          .resetPassword(param)
          .then((res) => {
            this.pageUtilService.showToast("success", res.code, res.message);
            this.loading = false;
            this.router.navigate(["/login"]);
          })
          .catch((err) => {
            this.pageUtilService.setApiError(this.resetPassMailForm, err);
            // トースターに全メッセージを表示する
            let message = `${err.error.message}\n\n`;
            let col;
            err.errorHeader.forEach((error) => {
              col = null;
              this.appLayout.cardList.some((card) => {
                card.rows.some((row) => {
                  col = row.cols.find((col) => col.controlName === error.target);
                  if (col) {
                    return true;
                  }
                });
                if (col) {
                  return true;
                }
              });
              message += `${error.code}: [${col.label}]  ${error.message}\n`;
            });
            this.pageUtilService.showToast("danger", err.error.code, message, {
              status: "danger",
              destroyByClick: true,
              duration: 3000,
              hasIcon: true,
              position: NbGlobalPhysicalPosition.TOP_RIGHT,
              preventDuplicates: false,
            });
            this.loading = false;
          });
      }
    }
  }

  createAppLayout(_this) {
    this.appLayout = {
      cardList: [
        {
          name: "パスワード再設定用ワンタイムパスワード メール送信",
          width: 12,
          status: "warning",
          rows: [
            {
              cols: [
                {
                  width: 2,
                },
                {
                  type: "input",
                  label: "メールアドレス",
                  controlName: "mailaddress",
                  maxlength: 254,
                  width: 8,
                  required: true,
                },
                {
                  width: 2,
                },
              ],
            },
            {
              cols: [
                {
                  width: 2,
                },
                {
                  type: "button",
                  label: "送信",
                  controlName: "changePasswordButton",
                  width: 8,
                  status: "info",
                  style: "default",
                  onClickParam: "",
                  fullWidth: true,
                },
                {
                  width: 2,
                },
              ],
            },
          ],
        },
      ],
    };
  }
}
