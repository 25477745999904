import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiExecuteModel } from "app/jb/model/api/apiExecute.model";
import {
  CodeDiscernmentSearchConditionModel,
  CodeListModel,
} from "app/jb/model/common/code-discernment.model";
import { environment } from "environments/environment";
import { ApiExecuteService } from "./apiExecute.service";

@Injectable({
  providedIn: "root",
})
export class CodeDiscernmentSearchService {
  // api setting
  getListApiParams = {
    uri: "/common/code",
    method: "POST",
    needsAuth: true,
    no: "001",
    description: "区分マスタの検索処理",
  };

  constructor(private apiExecuteService: ApiExecuteService) {}

  getList(param: CodeDiscernmentSearchConditionModel): Promise<CodeListModel[]> {
    console.log(environment.type);

    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.getListApiParams.needsAuth;
    apiExecuteModel.method = this.getListApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.getListApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    return new Promise<Array<CodeListModel>>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          // 対象データが見つからない場合、error.code == "CODE404"になる。この場合はthrowしない
          console.log(res.data);
          if (res.data === null) {
            if ("CODE404" != res.error.code) {
              throw new HttpErrorResponse({
                error: res.error,
                status: 400,
                statusText: "Bad Request",
                url: self.getListApiParams.uri,
              });
            } else {
              // エラー情報が格納されている場合はエラーオブジェクトを返す
              reject({
                error: res.error,
                errorHeader: res.errorHeader,
                errorDetail: res.errorDetail,
              });
            }
          } else {
            // 正常な結果が格納されている場合、メソッド仕様のデータ型変数を返す
            console.log(res.data.details);
            resolve(res.data.details as Array<CodeListModel>);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err);
        });
    });
  }
}
