import { AbstractControl, FormGroup } from "@angular/forms";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "jb-form-input",
  templateUrl: "./jb-form-input.component.html",
  styleUrls: ["./jb-form-input.component.scss"],
})
export class JbFormInputComponent implements OnInit {
  @Input() mode: "search" | undefined = undefined;
  @Input() parent: FormGroup;
  @Input() controlName: string;
  @Input() label: string;
  @Input() type: "text" | "number" | "email" = "text";
  @Input() fieldSize: string = "medium";
  @Input() placeholder: string = "";
  @Input() maxlength: number;
  @Input() readonly = false;
  // @Input() currency: boolean;
  // @Input() onlyNumber: boolean;
  // @Input() dateMode: "day" | "month" | undefined = undefined;
  // @Input() valueFix: string;
  @Input() messages: any;
  @Output() blur: EventEmitter<any>;
  @Output() clickSearchIcon: EventEmitter<any>;

  required: boolean;

  @Input() numberCommmaSeparate: boolean;
  mask: string = null;
  thousandSeparator: string = null;
  dropSpecialCharacters: boolean = true;

  constructor() {
    this.blur = new EventEmitter<any>();
    this.clickSearchIcon = new EventEmitter<any>();
  }

  ngOnInit(): void {
    const validator = this.formControl.validator
      ? this.formControl.validator({} as AbstractControl)
      : null;
    if (validator && validator.required) {
      this.required = true;
    }
    // 数値の桁区切り
    if (this.numberCommmaSeparate) {
      this.mask = "separator.2";
      this.thousandSeparator = ",";
    }
    // メールアドレス
    if (this.type === "email") {
      this.type = "text";
      this.mask = "A*@A*.A*.AAA";
      this.dropSpecialCharacters = false;
    }
  }

  get formControl() {
    const formControl = this.parent.get(this.controlName);
    return formControl;
  }

  // TODO
  getFormValue(controlName: string, readonly = false): string {
    const { value } = this.parent.get(controlName);
    // const v = this.valueFix
    //   ? this.valueFix
    //   : this.dateMode
    //   ? // ? this.dmp.transform(value, this.dateMode)
    //     // : this.currency
    //     this.cp.transform(value, "JPY")
    //   : this.onlyNumber
    //   ? this.dp.transform(value)
    //   : value;
    return value;
    // return readonly ? this.config.patchBlank(v) : v;
  }

  /**
   * ステータスチェック
   */
  checkFormControlStatus(formControl: AbstractControl) {
    return formControl.dirty
      ? formControl.invalid
        ? "danger"
        : "success"
      : "basic";
  }

  /**
   * 画面イベント
   * イベント種別と発生元のFormControlNameを返す
   */

  /**
   * フォーカスアウト
   */
  onBlur() {
    this.blur.emit({
      type: "blur",
      from: this.controlName,
      value: null,
    });
  }

  /**
   * 検索アイコンボタンクリック
   */
  onClickSearchIcon() {
    if (this.mode === "search") {
      this.clickSearchIcon.emit({
        type: "clickSearchIcon",
        from: this.controlName,
        value: null,
      });
    }
  }
}
