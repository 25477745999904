<nb-layout>
  <nb-layout-column>
    <div class="col-xl-6 offset-xl-3">
      <nb-card>
        <nb-card-body>
          <div class="text">
            パスワードを変更しました
          </div>
          <div *ngIf="buttonFlg" class="button">
            <button nbButton matRipple status="info" ghost (click)="onSubmit()">
              ログインへ
            </button>
          </div>
        </nb-card-body>
      </nb-card>
    </div>
  </nb-layout-column>
</nb-layout>