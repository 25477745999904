import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { AuthService } from "app/jb/service/common/auth.service";
/**
 * API Gateway署名バージョン4サービス
 *
 * @author shinya sugimoto
 * @link https://traveler0401.com/angular-api-gateway-iam/
 */
@Injectable({
  providedIn: "root",
})
export class ApigSigV4Service {
  checkHttpKey = "http:";
  checkHttpsKey = "https:";
  constructor(private authServire: AuthService) {}
  private CryptoJS = require("crypto-js"); // crypto-js
  private SERVICE = "execute-api"; // サービス名 ※API Gatewayの場合は下記で固定
  private AWS4_REQUEST = "aws4_request"; // AWS4リクエスト
  private AWS_SHA_256 = "AWS4-HMAC-SHA256"; // ハッシュアルゴリズム
  private AWS4 = "AWS4"; // AWS4
  private DEFAULT_CONTENT_TYPE = "application/json";
  private headerKey = {
    accept: "Accept",
    authorization: "Authorization",
    contentType: "Content-Type",
    host: "host",
    xAmzDate: "x-amz-date",
    xAmzSecurityToken: "x-amz-security-token",
  };
  /**
   * 現在時刻を取得する
   *
   * @return 現在時刻
   */
  private datetimeFor(): string {
    return new Date()
      .toISOString()
      .replace(/\.\d{3}Z$/, "Z")
      .replace(/[:\-]|\.\d{3}/g, "");
  }
  /**
   * URIをエンコードする
   *
   * @param  str クエリ文字列
   * @return URI(エンコード後)
   */
  private fixedEncodeURIComponent(str: string): string {
    return encodeURIComponent(str).replace(/[!'()*]/g, (c) => {
      return "%" + c.charCodeAt(0).toString(16).toUpperCase();
    });
  }
  /**
   * メッセージダイジェストを生成する(SHA-256)
   *
   * @param  value 任意の値
   * @return メッセージダイジェスト
   */
  private hash(value) {
    return this.CryptoJS.SHA256(value);
  }
  /**
   * ダイジェストのBase16エンコードを小文字で返す
   *
   * @param  value 任意の値
   * @return ダイジェストのBase16エンコード(小文字)
   */
  private hexEncode(value) {
    return value.toString(this.CryptoJS.enc.Hex);
  }
  /**
   * メッセージ認証コード化
   *
   * @param  secret シークレットキー
   * @param  value  値
   * @return メッセージ認証コード化後の値
   */
  private hmac(secret: string, value: string) {
    return this.CryptoJS.HmacSHA256(value, secret, { asBytes: true });
  }
  /**
   * 正規URI生成
   *
   * @param  uri URI ※HTTPホストヘッダーからクエリ文字列パラメータ(存在する場合)を開始する疑問符("?")までのすべて
   * @return 正規URI
   */
  private buildCanonicalUri(uri: string): string {
    return encodeURI(uri);
  }
  /**
   * 正規クエリ文字列生成
   *
   * @param  queryParams クエリパラメータ
   * @return 正規クエリ文字列
   */
  private buildCanonicalQueryString(queryParams: object): string {
    if (Object.keys(queryParams).length < 1) {
      return "";
    }
    const sortedQueryParams = [];
    for (const property in queryParams) {
      if (queryParams.hasOwnProperty(property)) {
        sortedQueryParams.push(property);
      }
    }
    sortedQueryParams.sort();
    let canonicalQueryString = "";
    for (let i = 0; i < sortedQueryParams.length; i++) {
      canonicalQueryString +=
        sortedQueryParams[i] +
        "=" +
        this.fixedEncodeURIComponent(queryParams[sortedQueryParams[i]]) +
        "&";
    }
    return canonicalQueryString.substr(0, canonicalQueryString.length - 1);
  }
  /**
   * 正規ヘッダ生成
   *
   * @param  headers ヘッダ
   * @return 正規ヘッダ
   */
  private buildCanonicalHeaders(headers: object): string {
    let canonicalHeaders = "";
    const sortedKeys = [];
    for (const property in headers) {
      if (headers.hasOwnProperty(property)) {
        sortedKeys.push(property);
      }
    }
    sortedKeys.sort();
    for (let i = 0; i < sortedKeys.length; i++) {
      canonicalHeaders +=
        sortedKeys[i].toLowerCase() + ":" + headers[sortedKeys[i]] + "\n";
    }
    return canonicalHeaders;
  }
  /**
   * 署名付きヘッダ生成
   *
   * @param  headers ヘッダ
   * @return 署名付きヘッダー
   */
  private buildCanonicalSignedHeaders(headers: object): string {
    const sortedKeys = [];
    for (const property in headers) {
      if (headers.hasOwnProperty(property)) {
        sortedKeys.push(property.toLowerCase());
      }
    }
    sortedKeys.sort();
    return sortedKeys.join(";");
  }
  /**
   * 正規リクエスト生成
   *
   * @param  method      HTTPメソッド ※大文字
   * @param  path        パス
   * @param  queryParams クエリパラメータ
   * @param  headers     ヘッダ
   * @param  payload     ペイロード
   * @return 正規リクエスト
   */
  private buildCanonicalRequest(
    method: string,
    path: string,
    queryParams: object,
    headers: object,
    payload: object | string
  ): string {
    return (
      method +
      "\n" +
      this.buildCanonicalUri(path) +
      "\n" +
      this.buildCanonicalQueryString(queryParams) +
      "\n" +
      this.buildCanonicalHeaders(headers) +
      "\n" +
      this.buildCanonicalSignedHeaders(headers) +
      "\n" +
      this.hexEncode(this.hash(payload))
    );
  }
  /**
   * 認証情報スコープ作成
   *
   * @param  region   リージョン名
   * @param  datetime 現在時刻
   * @return 認証情報スコープ
   */
  private buildCredentialScope(region: string, datetime: string): string {
    return (
      datetime.substr(0, 8) +
      "/" +
      region +
      "/" +
      this.SERVICE +
      "/" +
      this.AWS4_REQUEST
    );
  }
  /**
   * 署名文字列作成
   *
   * @param  datetime               現在時刻
   * @param  credentialScope        認証情報スコープ
   * @param  hashedCanonicalRequest 正規リクエスト(ハッシュ化後)
   * @return 署名文字列
   */
  private buildStringToSign(
    datetime: string,
    credentialScope: string,
    hashedCanonicalRequest: string
  ): string {
    return (
      this.AWS_SHA_256 +
      "\n" +
      datetime +
      "\n" +
      credentialScope +
      "\n" +
      hashedCanonicalRequest
    );
  }
  /**
   * 署名キー取得
   *
   * @param  region    リージョン
   * @param  secretKey シークレットキー
   * @param  datetime  現在日付
   * @return 署名キー
   */
  private calculateSigningKey(
    region: string,
    secretKey: string,
    datetime: string
  ): string {
    return this.hmac(
      this.hmac(
        this.hmac(
          this.hmac(this.AWS4 + secretKey, datetime.substr(0, 8)),
          region
        ),
        this.SERVICE
      ),
      this.AWS4_REQUEST
    );
  }
  /**
   * 署名計算
   *
   * @param  key          キー
   * @param  stringToSign 署名文字列
   * @return 署名
   */
  private calculateSignature(key: string, stringToSign: string) {
    return this.hexEncode(this.hmac(key, stringToSign));
  }
  /**
   * Authorizationヘッダ署名情報取得
   *
   * @param accessKey       アクセスキー
   * @param credentialScope 認証情報スコープ
   * @param headers         ヘッダ
   * @param signature       署名
   */
  private buildAuthorizationHeader(
    accessKey: string,
    credentialScope: string,
    headers: object,
    signature
  ): string {
    return (
      this.AWS_SHA_256 +
      " Credential=" +
      accessKey +
      "/" +
      credentialScope +
      ", SignedHeaders=" +
      this.buildCanonicalSignedHeaders(headers) +
      ", Signature=" +
      signature
    );
  }
  /**
   * APIGatewayのFQDNのうち、ホスト名部分のみを算出し返却する
   *
   * @param path /restapi/test 等
   */
  private getApiFqdn(): string {
    // 変数格納クラスよりAPIGatewayのFQDNを取得
    let apifqdn = this.authServire.getApiFqdn();
    // 末尾に"/"が含まれる場合、除去する
    if (apifqdn.substr(-1) === "/") {
      apifqdn = apifqdn.substr(0, apifqdn.length - 1);
    }
    // 先頭文字がhttp:かhttpsかを判定し、先頭除去文字数を決定する
    let cutNum = 0;
    if (apifqdn.indexOf(this.checkHttpKey) != -1) {
      cutNum = 7; // http:// の7文字を除去する
    } else if (apifqdn.indexOf(this.checkHttpsKey) != -1) {
      cutNum = 8; // https:// の8文字を除去する
    }
    // 先頭文字を除去し、ホスト名部分を算出する
    if (cutNum !== 0) {
      apifqdn = apifqdn.substr(cutNum);
    }
    // 返却
    return apifqdn;
  }
  /**
   * 署名バージョン4を作成する
   * @param  method      HTTPメソッド ※大文字
   * @param  path        パス
   * @param  queryParams クエリパラメータ
   * @param  headers     ヘッダ
   * @param  body        ボディ
   * @return 署名バージョン4
   * @link   https://docs.aws.amazon.com/ja_jp/general/latest/gr/sigv4_signing.html
   * @caution 実行前にAWSCredentialsのrefreshを実施すること
   */
  public makeRequest(
    method: string,
    path: string,
    queryParams: object,
    headers: object,
    body: object | string
  ): Promise<any> {
    let self = this;
    return new Promise(function (resolve, reject) {
      /**
       * 初期処理
       */
      const datetime = self.datetimeFor();
      const region = environment.aws.region;
      if (
        body === undefined ||
        body === "" ||
        body === null ||
        Object.keys(body).length === 0
      ) {
        body = undefined;
      }
      if (queryParams === undefined) {
        queryParams = {};
      }
      if (headers === undefined) {
        headers = {};
      }
      if (headers[self.headerKey.contentType] === undefined) {
        headers[self.headerKey.contentType] = self.DEFAULT_CONTENT_TYPE;
      }
      if (headers[self.headerKey.accept] === undefined) {
        headers[self.headerKey.accept] = self.DEFAULT_CONTENT_TYPE;
      }
      if (body === undefined || method === "GET") {
        body = "";
      } else {
        body = JSON.stringify(body);
      }
      // ボディがない場合、ヘッダからコンテンツタイプを削除し、署名バージョン4の計算に含まれないようにする
      if (body === "" || body === undefined || body === null) {
        delete headers[self.headerKey.contentType];
      }
      headers[self.headerKey.xAmzDate] = datetime;
      headers[self.headerKey.host] = self.getApiFqdn();

      /**
       * タスク1：署名バージョン4の正規リクエストを作成する
       *
       * @link https://docs.aws.amazon.com/ja_jp/general/latest/gr/sigv4-create-canonical-request.html
       */
      const canonicalRequest = self.buildCanonicalRequest(
        method,
        path,
        queryParams,
        headers,
        body
      );
      const hashedCanonicalRequest = self.CryptoJS.SHA256(
        canonicalRequest
      ).toString(self.CryptoJS.enc.Hex);
      /**
       * タスク2：署名バージョン4の署名文字列を作成する
       *
       * @link https://docs.aws.amazon.com/ja_jp/general/latest/gr/sigv4-create-string-to-sign.html
       */
      const credentialScope = self.buildCredentialScope(region, datetime);
      const stringToSign = self.buildStringToSign(
        datetime,
        credentialScope,
        hashedCanonicalRequest
      );
      /**
       *一時認証情報(STS)を取得する
       */

      let accessKeyId = "";
      let secretAccessKey = "";
      let sessionToken = "";
      // IAMのリフレッシュを実施する
      self.authServire
        .refreshCredentials(self.authServire.getTokenObj(), false)
        .then((res) => {
          const credentials = self.authServire.getCredentials();
          accessKeyId = credentials.accessKeyId;
          secretAccessKey = credentials.secretAccessKey;
          sessionToken = credentials.sessionToken;

          /**
           * タスク3: AWS署名バージョン4の署名を計算する
           *
           * @link https://docs.aws.amazon.com/ja_jp/general/latest/gr/sigv4-calculate-signature.html
           */
          const signingKey = self.calculateSigningKey(
            region,
            secretAccessKey,
            datetime
          );
          const signature = self.calculateSignature(signingKey, stringToSign);
          /**
           * タスク4: HTTPリクエストに署名を追加する
           *
           * @link https://docs.aws.amazon.com/ja_jp/general/latest/gr/sigv4-add-signature-to-request.html
           */
          headers[self.headerKey.authorization] = self.buildAuthorizationHeader(
            accessKeyId,
            credentialScope,
            headers,
            signature
          );
          /**
           * ヘッダ生成処理
           */
          headers[self.headerKey.xAmzSecurityToken] = sessionToken;
          delete headers[self.headerKey.host];
          // コンテンツタイプが指定されていない場合、再アタッチする必要あり
          if (headers[self.headerKey.contentType] === undefined) {
            headers[self.headerKey.contentType] = self.DEFAULT_CONTENT_TYPE;
          }

          // 生成したheaderを返却する
          resolve(headers);
        })
        .catch((err) => {
          console.log("refresh credentials error");
          reject(err);
        });
    });
  }
}
