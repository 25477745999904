<div [formGroup]="parent">
  <label [for]="controlName" class="label pull-top pull-left"
    >{{ label
    }}<span *ngIf="required" class="required-mark">&nbsp;*</span></label
  >
  <nb-select
    fullWidth
    placeholder=""
    [formControlName]="controlName"
    [selected]="formControl"
    [multiple]="isMultiple"
    [size]="fieldSize"
    [status]="checkFormControlStatus(formControl)"
    (selectedChange)="onSelectedChange($event)"
    >
    <nb-option *ngFor="let option of options" [value]="option.value">{{
      option.label
    }}</nb-option>
  </nb-select>
  <ng-container *ngIf="formControl.invalid && formControl.touched">
    <p class="caption status-danger" *ngIf="formControl.errors?.required">
      {{ messages.required }}
    </p>
    <ng-container *ngIf="formControl.errors?.apiError">
      <p
        class="caption status-danger"
        *ngFor="let apiError of formControl.errors.apiError"
      >
        {{ apiError.code + ": " + apiError.message }}
      </p>
    </ng-container>
  </ng-container>
</div>
