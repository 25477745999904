import { Contacts } from "./../../../@core/data/users";

import { ChangeDetectorRef, ElementRef, Injectable } from "@angular/core";
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  ValidatorFn,
  Validators,
} from "@angular/forms";
import {
  NbComponentStatus,
  NbGlobalPhysicalPosition,
  NbToastrService,
} from "@nebular/theme";
import * as moment from "moment-timezone";
import { Cell, LocalDataSource } from "ng2-smart-table";
import { BehaviorSubject } from "rxjs";
import { ApiResultJsonModel } from "app/jb/model/api/apiResultJson.model";
import { ApiResultErrorDetailJsonModel } from "app/jb/model/api/apiResultErrorDetailJsonModel";

import { CodeDiscernmentSearchService } from "./../common/code-discernment-search.service";
import {
  CodeListModel,
  CodeDiscernmentSearchConditionModel,
} from "./../../model/common/code-discernment.model";

@Injectable({
  providedIn: "root",
})
export class PageUtilService {
  // 画面項目共通メッセージ
  messages: any = {
    required: "必須項目です",
    notmatch: "確認用パスワードが一致しません",
    notpass: "パスワードおよびパスワード確認は8桁以上の英大文字小文字数字で入力してください"
  };
  // ページング件数
  selectPerPageOptions: Array<number> = [10, 20, 30, 40, 50, 100];
  perPage: number = 10;

  // 区分マスタの配列
  optionSubject: BehaviorSubject<Map<string, Array<any>>>;

  //tableとのデータ送受信に使用するSubject
  tableMessageSubject: BehaviorSubject<any>;
  tableEventSubject: BehaviorSubject<any>;

  // トーストメッセージインデックス
  toastIndex: number = 0;

  // お知らせマスタ用の区分識別コード
  infoCode: string = "info_cd";

  constructor(
    private nbToastrService: NbToastrService,
    private fb: FormBuilder,
    private codeDiscernmentSearchService: CodeDiscernmentSearchService
  ) {
    this.optionSubject = new BehaviorSubject<Map<string, Array<any>>>(
      new Map<string, Array<CodeListModel>>()
    );
    this.tableMessageSubject = new BehaviorSubject<Array<any>>(
      new Array<any>()
    );
    this.tableEventSubject = new BehaviorSubject<any>({});
  }

  /**
   * プルダウン/ラジオボタンの選択肢を取得し画面部品に通知する
   * @param codediscernmentcdList {Array<string>} 区分識別コードの配列
   */
  getOptions(codediscernmentcdList: Array<string>) {
    if (!codediscernmentcdList || codediscernmentcdList.length === 0)
      return null;

    let requests = codediscernmentcdList.map((_codeid) => {
      return this.codeDiscernmentSearchService.getList({
        codeid: _codeid,
      } as CodeDiscernmentSearchConditionModel);
    });

    // 区分識別コードをキーにしたマップを作成する
    let options = new Map<string, Array<any>>();
    Promise.all(requests)
      .then((responses) => {
        responses.forEach((response) => {
          //console.log(requests)
          // 先頭に未選択項目を追加する
          // response.unshift({
          //   codeid: response[0].codeid,
          //   codestring: "",
          //   codename: "",
          //   codeinteger: null,
          //   codelink: "",
          //   namestring: `\u00A0`,
          //   sort: null
          // } as CodeListModel);
          options.set(
            response[0].codeid,
            response.map((val) => {
              return {
                value: val.codestring,
                label: val.namestring,
              };
            })
          );
        });
        // 既存のマップに追記する
        const newOptions: Map<string, Array<any>> = new Map([
          ...Array.from(this.optionSubject.getValue()),
          ...Array.from(options),
        ]);
        this.optionSubject.next(newOptions);
        console.log("optionSubject: " + this.optionSubject.getValue());
      })
      .catch((err) => {
        console.log(err);
        this.optionSubject.next(new Map<string, Array<any>>());
      });
  }

  /**
   * トーストメッセージ表示
   * @param type {NbComponentStatus} - トーストメッセージのステータス
   * @param title {string} - トーストメッセージのタイトル
   * @param body {string} - トーストメッセージの本文
   * @param config? {any} - トースターの設定
   */
  showToast(
    type: NbComponentStatus,
    title: string,
    body: string,
    config?: any
  ) {
    const _config = config
      ? config
      : {
          status: type,
          destroyByClick: true,
          duration: 3000,
          hasIcon: true,
          position: NbGlobalPhysicalPosition.TOP_RIGHT,
          preventDuplicates: false,
        };
    const titleContent = title ? `. ${title}` : "";

    this.toastIndex += 1;
    this.nbToastrService.show(body, `通知 ${titleContent}`, _config);
  }

  /**
   * エラートーストにラベル付きメッセージを一括表示する
   * @param errors {ApiResultJsonModel}
   * @param appLayout {any}
   */
  showToastMessages(
    type: NbComponentStatus,
    errors: ApiResultJsonModel,
    appLayout: any,
    duration: number = 3000
  ) {
    let message = `${errors.error.message}\n\n`;
    let col;
    errors.errorHeader.forEach((error) => {
      col = null;
      appLayout.cardList.some((card) => {
        card.rows.some((row) => {
          col = row.cols.find((col) => col.controlName === error.target);
          if (col) {
            return true;
          }
        });
        if (col) {
          return true;
        }
      });
      message += `${error.code}: [${col.label}]  ${error.message}\n`;
    });
    this.showToast("danger", errors.error.code, message, {
      status: "danger",
      destroyByClick: true,
      duration: duration,
      hasIcon: true,
      position: NbGlobalPhysicalPosition.TOP_RIGHT,
      preventDuplicates: false,
    });
  }

  /**
   * 入力値補正
   * APIとのフォーマット差異等を補正する
   * @param param {any} - 補正対象データ FromGroup.getRawValues()の実行結果
   *                      または apiから返却されるdataオブジェクト
   * @param checkBoxItems {Array<string>} - チェックボックスの項目IDリスト
   * @param dateItems {Array<string>} - 日付の項目IDリスト
   * @param mode {enum} - 処理モード "fromApi" | "toApi"
   * @return {any} 補正後のオブジェクト
   * @see https://momentjs.com/docs/#/displaying/format/
   */
  correctValue(
    param: any,
    checkBoxItems: Array<string>,
    dateItems: Array<string>,
    mode: "fromApi" | "toApi" = "toApi"
  ): any {
    if (mode === "toApi") {
      checkBoxItems.forEach((item) => {
        param[item] = param[item] ? "1" : "0";
      });
      
      dateItems.forEach((item) => {
        //let date: Date =new Date(param[item]);
        param[item] = param[item]
          //  ? moment(param[item]).format("YYYY-MM-DD HH:mm:ss")
           ? moment(new Date(param[item])).tz('Asia/Tokyo').toISOString(true)
          : "";
      });
      // nullを空文字に変換する
      for (let k of Object.keys(param)) {
        param[k] = param[k] === null ? "" : param[k];
      }
    } else {
      checkBoxItems.forEach((item) => {
        param[item] = param[item] === "1" ? true : false;
      });
      dateItems.forEach((item) => { 
        if(item != "modifiedate"){
          param[item] = param[item]
            ? moment(moment(param[item]).toDate()).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm")
            : "";
        }
        if(item == "modifiedatedisplay") {
          param["modifiedatedisplay"] = param["modifiedate"]
            ? moment(moment(param["modifiedate"]).toDate()).tz("Asia/Tokyo").format("YYYY-MM-DD HH:mm")
            : "";
        }
      });
    }
    return param;
  }

  /**
   * APIエラーレスポンスのtargetから対応するFormContorolを検索しエラー状態にする
   * @param formGroup {FormGroup} - エラー表示対象のFormGroup
   * @param apiError {any} - APIから返却されるエラーオブジェクト
   * @param tableNames? {Array<string>} - テーブル名の配列、引数に渡した場合APIメッセージ内のtargetを上書きする
   * @see https://angular.jp/api/forms/AbstractControl#seterrors
   */
  setApiError(formGroup: FormGroup, apiError: any, tableNames?: Array<string>) {
    // 項目IDをキーにメッセージを集約したマップを作成する
    const errorHeaderMap = apiError.errorHeader?.reduce((acc, item) => {
      if (acc.get(item.target)) {
        acc.get(item.target).push(item);
      } else {
        acc.set(item.target, new Array<any>(item));
      }
      return acc;
    }, new Map<string, Array<any>>());

    // FormControl経由で集約したメッセージをまとめてセット
    errorHeaderMap?.forEach((value, key) => {
      let formControl = formGroup.get(key);
      if (formControl) {
        formControl.setErrors({
          apiError: value,
        });
        formControl.markAsDirty();
        formControl.markAsTouched();
      }
    });

    // errorDetail
    if (apiError.errorDetail) {
      let nextMessages = new Array<any>();
      for (let k of Object.keys(apiError.errorDetail)) {
        let tableMessages = apiError.errorDetail[k];
        if (tableNames && tableNames.length > 0) {
          // テーブル名が渡されている場合はキー値を条件に置き換える
          tableMessages.map(
            (message) =>
              (message.target = tableNames[k] ? tableNames[k] : message.target)
          );
        }
        nextMessages = nextMessages.concat(tableMessages);
      }
      if (nextMessages.length > 0) {
        this.tableMessageSubject.next(nextMessages);
      }
    }
  }

  /**
   * APIエラーレスポンスのエラーを項目単位でセットする
   * @param formControl {AbstractControl} エラー表示対象の項目
   * @param apiErrors {Array<any>} 表示対象のエラー（code/messageを保持したオブジェクト）配列
   */
  setApiErrorByItem(formControl: AbstractControl, apiErrors: Array<any>) {
    formControl.setErrors({
      apiError: apiErrors,
    });
    formControl.markAsDirty();
    formControl.markAsTouched();
  }

  /**
   * 画面レイアウトJSONからFormGroupを生成する
   * @param appLayout {any} - 画面レイアウトJSON
   * @return {FormGroup}
   */
  createForm(appLayout: any): FormGroup {
    let formGroup = this.fb.group({});
    appLayout.cardList.forEach((card) => {
      card.rows.forEach((row) => {
        row.cols.forEach((col) => {
          // バリデーション
          let validators = new Array<ValidatorFn>();
          if (col.required) validators.push(Validators.required);
          // if (col.validateCompare) validators.push(CompareValidator.compare(this.changePassForm.controls.passwordconfirmation, '確認用パスワードが一致しません'));
          if (col.maxLength)
            validators.push(Validators.maxLength(col.maxLength));
          if (col.minLength)
            validators.push(Validators.minLength(col.minLength));
          if (col.pattern) validators.push(Validators.pattern(col.pattern));

          // 初期値
          let initValue = null;
          if (col.type === "input") {
            if (col.inputType === "number") {
              initValue = 0;
            } else {
              initValue = "";
            }
          } else if (col.type === "check") {
            initValue = false;
          } else if (col.type === "selectMultiple") {
            initValue = [];
          } else {
            initValue = "";
          }

          // FormControl登録
          formGroup.addControl(
            col.controlName,
            this.fb.control({ value: initValue, disabled: false }, validators)
          );
        });
      });
    });
    return formGroup;
  }

  /**
   * 登録/修正/削除ボタンの表示切替
   * @param appLayout
   * @param procMode
   */
  changeProcButtonDisplay(appLayout: any, procMode: string) {
    // ボタンの表示切替
    appLayout.cardList.forEach((card) => {
      if (card.buttonCard) {
        card.rows.forEach((row) => {
          row.cols.map((col) => {
            switch (col.controlName) {
              case "registerButton":
              case "editButton":
                col.hidden = procMode === "display" ? false : true;
                break;
              case "updateButton":
                col.hidden = procMode === "display" ? true : false;
                break;
              default:
                break;
            }
          });
        });
      }
    });
  }

  // テーブル行メッセージ更新
  updateTableRowMessage(lineNo: string, newMessages: Array<any>) {
    let messages: Array<any> = this.tableMessageSubject.getValue();
    messages = messages.filter((message) => message.rowNo !== lineNo);
    this.tableMessageSubject.next(messages.concat(newMessages));
  }

  /**
   * コードチェック用テーブルメッセージ更新
   * @param err {ApiResultJsonModel} コードチェックAPIの結果
   * @param event {any} tableのイベントオブジェクト
   */
  updateTableRowMessageCodeCheck(err: ApiResultJsonModel, event: any) {
    let detailError = new ApiResultErrorDetailJsonModel();
    detailError.code = err.error.code;
    detailError.target = event.from;
    detailError.message = err.error.message;
    detailError.rowNo = event.value.lineNo;
    detailError.colId = event.value.colId;
    this.updateTableRowMessage(event.value.lineNo, new Array<any>(detailError));
  }

  // テーブル行メッセージ削除
  deleteTableRowMessage(lineNo: string) {
    let messages: Array<any> = this.tableMessageSubject.getValue();
    this.tableMessageSubject.next(
      messages.filter((message) => message.rowNo !== lineNo)
    );
  }

  // テーブル行メッセージ初期化
  clearTableMessage() {
    this.tableMessageSubject.next(new Array<any>());
  }

  // テーブルサブジェクト初期化
  clearTableSubjects() {
    this.tableEventSubject.next({});
    this.tableMessageSubject.next(new Array<any>());
  }

  // サブジェクト初期化
  clearSubjects() {
    this.tableEventSubject.next({});
    this.tableMessageSubject.next(new Array<any>());
  }

  /**
   * セル情報取得
   * テーブル内コンポーネントが自身の位置を特定するための情報
   * @param element {ElementRef} テーブル内コンポーネントのDOMオブジェクト
   * @param cell {Cell} テーブルのCellオブジェクト（編集モード時使用）
   * @param rowData {any} テーブルの行データ（照会モード時使用）
   * @param _colId {string} テーブル列の項目ID（照会モード時使用）
   * @return {string, string, string} { tableId, lineNo, colId }
   */
  getCellInfo(element: ElementRef, cell: Cell, rowData: any, _colId: string) {
    let tableId = "";
    let children = element.nativeElement.offsetParent.offsetParent.children;
    for (let i = 0; i < children.length; i++) {
      if (children[i].localName === "ng2-smart-table") {
        tableId = children[i].id;
        break;
      }
    }
    let lineNo, colId;
    // セルオブジェクトの有無で動作モードを判定
    // 存在しない場合編集モード
    if (cell) {
      lineNo = cell.getRow().getData().lineNo;
      colId = cell.getColumn().id;
    } else {
      lineNo = rowData.lineNo;
      colId = _colId;
    }
    return { tableId, lineNo, colId };
  }

  /**
   * 追加行の識別番号取得
   * @param event {any} createConfirmのイベントオブジェクト
   * @return {string} 識別番号（ADD_<連番>）
   */
  getAddRowNo(event: any): string {
    let maxlineNo = 0;
    // 追加業を抽出
    let addRows = event.value.source.data.filter((row) =>
      /ADD/g.test(row.lineNo)
    );
    if (addRows.length > 0) {
      // 追加行内の最大を取得
      maxlineNo = Math.max.apply(
        Math,
        addRows.map(function (row) {
          return Number.parseInt(row.lineNo.split("_")[1]);
        })
      );
    }
    return `ADD_${maxlineNo + 1}`;
  }

  /**
   * セルの表示切替制御メッセージを取得する
   * テーブル内のフォーカスアウト（Blur）イベント時に使用する想定
   * @param tableBlurEvent {any} テーブルのBlurイベントオブジェクト
   * @param colId {strng} 列ID
   * @param editable {boolean} 編集可否
   */
  getChangeCellEditableMessage(
    tableBlurEvent: any,
    colId: string,
    editable: boolean
  ) {
    let chargeEvent = {
      type: "changeCellEditable",
      from: tableBlurEvent.from,
      value: {
        lineNo: tableBlurEvent.value.lineNo,
        colId: colId,
        editable: editable,
      },
    };
    return chargeEvent;
  }

  /**
   * テーブルの表示ページ、フィルター、ソートを初期化する
   * @param source {LocalDataSource} テーブルのデータソース
   */
  resetTableDisplay(source: LocalDataSource) {
    source.setPage(1);
    source.setFilter([]);
    source.setSort([]);
  }

  /**
   * テーブルを再描画する
   * @param appLayout {any}
   * @param settings {any}
   * @param tableControlName {string} appLayoutで定義しているテーブルのcontrolName
   */
  async redrawTableDisplay(
    appLayout: any,
    settings: any,
    tableControlName: string
  ) {
    let _col;
    appLayout.cardList.forEach((card) => {
      card.rows.forEach((row) => {
        row.cols.map((col) => {
          if (col.controlName === tableControlName) {
            col.settings = settings;
            col.type = null;
            _col = col;
          }
        });
      });
    });
    let id = setInterval(() => {
      _col.type = "table";
      clearInterval(id);
    });
  }
}
