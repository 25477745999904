import { ApiExecuteModel } from "../../model/api/apiExecute.model";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { ApiExecuteService } from "../common/apiExecute.service";
import { HttpErrorResponse } from "@angular/common/http";
import { PasswordModel } from "app/jb/model/password/password.model";

@Injectable({
  providedIn: "root",
})
export class PasswordService {
  // api setting
  updateApiParams = {
    uri: "/pass/update",
    method: "POST",
    needsAuth: true,
    no: "001",
    description: "パスワード更新処理",
  };

  resetPassApiParams = {
    uri: "/pass/sendmail",
    method: "POST",
    needsAuth: false,
    no: "002",
    description: "パスワード再設定メール送信",
  };

  constructor(private apiExecuteService: ApiExecuteService) {}

  update(param: any): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.updateApiParams.needsAuth;
    apiExecuteModel.method = this.updateApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.updateApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          // // 対象データが見つからない場合、error.code == "CODE404"になる。この場合はthrowしない
          // if (res.data === null) {
          //   if ("CODE404" != res.error.code) {
          //     throw new HttpErrorResponse({
          //       error: res.error,
          //       status: 400,
          //       statusText: "Bad Request",
          //       url: self.getInitDataApiParams.uri,
          //     });
          //   } else if (res.error) {
          //     // エラー情報が格納されている場合はエラーオブジェクトを返す
          //     reject(res);
          //   }
          // } else {
          //   // 正常な結果が格納されている場合、メソッド仕様のデータ型変数を返す
          //   resolve(res.data);
          // }
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }
  resetPassword(param): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.resetPassApiParams.needsAuth;
    apiExecuteModel.method = this.resetPassApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.resetPassApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          if (res.error) {
            reject(res); 
          } else { 
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }
}
