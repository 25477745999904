  <nb-layout>
    <nb-layout-column
    [nbSpinner]="loading" 
    nbSpinnerStatus="success" 
    >
    <div class="row">
      <form class="col-xl-12" [formGroup]="loginForm">
        <div class="row">
          <div class="col-xl-6 offset-xl-3">
            <jb-form-auto-layout
              [parent]="loginForm"
              [appLayout]="appLayout"
              fieldSize="small"
              [messages]="messages"
              (childEvent)="onChildEvent($event)"
            ></jb-form-auto-layout>
          </div>
        </div>
      </form>
    </div>
  </nb-layout-column>
</nb-layout>
