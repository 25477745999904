import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { AuthService } from "app/jb/service/common/auth.service";
import { LoginService } from "app/jb/service/login/login.service";
import { sha256 } from 'js-sha256';

import { PageUtilService } from "app/jb/service/common/page-util.service";
import { ValueSharedService } from "app/jb/service/common/value-shared.service";

/**
 * ログイン画面
 * @author
 */
@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  appLayout: any;
  messages: any;
  loading = false;


  constructor(
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private loginSearvice: LoginService,
    private pageUtilService: PageUtilService,
    private valueSharedService: ValueSharedService,
  ) {
    this.messages = pageUtilService.messages;
  }

  /**
   * 初期化処理
   */
  ngOnInit() {
    this.loginForm = this.fb.group({
      mailaddress: [{ value: '', disabled: false }, [Validators.required]],
      password: [{ value: '', disabled: false }, [Validators.required]],
    });
    this.authService.clearAuthentication();
    this.createAppLayout(this);
    
  }

  ngOnDestroy(): void {
      this.pageUtilService.clearSubjects();
  }

  /**
   * ログインボタンClickイベント
   */
  async loginbutton_click() {
    let isfirst: boolean;
    let isreset: boolean;
    let islimit: boolean;
    let usertype: String;
    let userid: String;
    let username: String;

    this.loading = true;
      let param = {
        mailaddress: this.loginForm.controls.mailaddress.value,
        password: this.loginForm.controls.password.value =="" ? "" : sha256(this.loginForm.controls.password.value)
      };
      //SignIn認証APIを呼出し
      await this.loginSearvice.login(param)
        .then(async (res: any) => {
          //認証結果をAuthServiceにセット
          this.authService.setTokenObj(res.token);
          this.authService.setXAppToken(res.xapptoken);
          this.authService.setApiFqdn(res.apifqdn);
          isfirst = res.isfirst;
          this.valueSharedService.isfirst = isfirst;
          isreset = res.isreset;
          this.valueSharedService.isreset = isreset;
          islimit = res.islimit;
          this.valueSharedService.islimit = islimit;
          usertype = res.usertype;
          userid = res.userid;
          username = res.username;
        })
        .then(async () => {
          let userInfo = {
            mailaddress: this.loginForm.controls.mailaddress.value,
            usertype: usertype,
            userid: userid,
            username: username,
          };
          //ユーザー情報（メールアドレス）をセッションストレージに格納
          this.authService.setUserInfomation(userInfo);
          this.loading = false;
          if (isfirst || isreset || islimit) {
            //パスワード変更画面へ遷移
            this.router.navigate(['/change-pass2']);
          } else if (usertype == "2"){
            //ロンシール管理者ならホーム画面画面へ遷移
            this.router.navigate(['/home']);
          }
          else {
            //一般ユーザーならハウスメーカー選択画面へ遷移
            this.router.navigate(['/page/house-maker']);
          }
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
          this.pageUtilService.setApiError(this.loginForm, err);
          this.pageUtilService.showToast(
            "danger",
            err.error.code,
            err.error.message
          );
        });
  }
  // 子コンポーネントのイベントハンドリング
  onChildEvent(event: any) {
    // ボタン
    if (event.type === "buttonClick") {
      if (event.from === "loginButton") {
        this.loginbutton_click();
      } else if (event.from === "resetPassButton") {
        this.router.navigate(["/reset-pass-mail"]);
      }
    }
  }

  createAppLayout(_this) {
    
    this.appLayout = {
      cardList: [
        {
          name: "ログイン",
          width: 12,
          status: "info",
          rows: [
            {
              cols: [
                { width: 2,},
                { type: "input", label: "メールアドレス", controlName: "mailaddress", width: 8, },
                { width: 2,},
              ],
            },
            {
              cols: [
                { width: 2,},
                { type: "input", inputType: "password", label: "パスワード", controlName: "password", width: 8, },
                { width: 2,},
              ],
            },
            {
              cols: [
                { width: 5,},
                // { type: "button", label: "ログイン", controlName: "loginButton",size: "small",status: "info", disabled: "true"},
                {type: "button", label: "ログイン", controlName: "loginButton",size: "small",status: "info",},
              ],
            },
            {
              cols: [
                { width: 4,},
                {type: "button", label: "パスワードをお忘れの方はこちら",size: "small", controlName: "resetPassButton",status: "basic", },
              ],
            },

          ],
        },
      ],
      
    };
  }

}