import { Injectable } from '@angular/core';
import { ErrorModel } from 'app/jb/model/common/error.model';

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  constructor() { }

  errorJson: ErrorModel;

}
