import { environment } from "environments/environment";
import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class MockHttpInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler) {
    console.log("intercept");
    console.log(req);
    if (environment.type === "mock") {
      const mockeReq = req.clone({
        url: `/app/jb/mock/${req.method}/${req.url.replace(
          /\:|\//g,
          "_"
        )}.json`,
        method: "get",
      });
      console.log(mockeReq);
      return next.handle(mockeReq);
    } else {
      return next.handle(req);
    }
  }
}
