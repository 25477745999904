import { ApiExecuteService } from "app/jb/service/common/apiExecute.service";
import { ApiExecuteModel } from "app/jb/model/api/apiExecute.model";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import { from, Observable, of } from "rxjs";
import { HttpErrorResponse } from "@angular/common/http";
import { OrderlistModel } from "app/jb/model/order/orderlist.model";

@Injectable({
  providedIn: "root",
})
export class OrderService {
  // api setting
  addApiParams = {
    uri: "/order/insert",
    method: "POST",
    needsAuth: true,
    no: "001",
    description: "注文カートに通常製品追加処理",
  };

  getListApiParams = {
    // uri: "/order/list",
    uri: "/order/orderlist",
    method: "POST",
    needsAuth: true,
    no: "002",
    description: "注文の一覧取得処理",
  };

  updateApiParams = {
    uri: "/order/update",
    method: "POST",
    needsAuth: true,
    no: "005",
    description: "注文の更新処理",
  }; 

  deleteApiParams = {
    uri: "/order/delete",
    method: "POST",
    needsAuth: true,
    no: "004",
    description: "注文の削除処理",
  }; 

  addcustomApiParams = {
    uri: "/ordercustom/insert",
    method: "POST",
    needsAuth: true,
    no: "006",
    description: "注文カートに特注品追加処理",
  };

  updatecustomApiParams = {
    uri: "/ordercustom/update",
    method: "POST",
    needsAuth: true,
    no: "005",
    description: "注文の更新処理",
  }; 
  
  deletecustomApiParams = {
    uri: "/ordercustom/delete",
    method: "POST",
    needsAuth: true,
    no: "005",
    description: "注文の削除処理",
  }; 

  deleteallApiParams = {
    uri: "/order/deleteall",
    method: "POST",
    needsAuth: true,
    no: "006",
    description: "注文(通常品、特注品)の削除処理",
  };

  confirmApiParams = {
    uri: "/order/confirm",
    method: "POST",
    needsAuth: true,
    no: "006",
    description: "注文(通常品、特注品)の注文確定処理",
  };

  constructor(private apiExecuteService: ApiExecuteService) {
    
  }

  add(param: any): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.addApiParams.needsAuth;
    apiExecuteModel.method = this.addApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.addApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    console.log(apiExecuteModel);
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }

  getList(param: any): Observable<OrderlistModel> {
    console.log(environment.type);

    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.getListApiParams.needsAuth;
    apiExecuteModel.method = this.getListApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.getListApiParams.uri;
    apiExecuteModel.body = param;
    // apiExecuteModel.queryParams = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    return from(
      new Promise<OrderlistModel>((resolve, reject) => {
        this.apiExecuteService
          .executeApi(apiExecuteModel)
          .then((res) => {
            console.log(res);
            // 対象データが見つからない場合、error.code == "CODE404"になる。この場合はthrowしない
            console.log(res.data);
            if (res.data === null) {
              if ("CODE404" != res.error.code) {
                throw new HttpErrorResponse({
                  error: res.error,
                  status: 400,
                  statusText: "Bad Request",
                  url: self.getListApiParams.uri,
                });
              } else {
                // エラー情報が格納されている場合はエラーオブジェクトを返す
                // reject({
                //   error: res.error,
                //   errorHeader: res.errorHeader,
                //   errorDetail: res.errorDetail,
                // });
                resolve(null);
              }
            } else {
              // 正常な結果が格納されている場合、メソッド仕様のデータ型変数を返す
              resolve(res.data.details as OrderlistModel);
            }
          })
          .catch((err) => {
            console.error(err);
            console.log(`Api execution failed: ${err.message}`);
            reject(err);
          });
      })
    );
  }

  getOrderedList(param: any): Observable<Array<any>> {
    console.log(environment.type);

    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.getListApiParams.needsAuth;
    apiExecuteModel.method = this.getListApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.getListApiParams.uri;
    apiExecuteModel.body = param;
    // apiExecuteModel.queryParams = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    return from(
      new Promise<Array<any>>((resolve, reject) => {
        this.apiExecuteService
          .executeApi(apiExecuteModel)
          .then((res) => {
            console.log(res);
            // 対象データが見つからない場合、error.code == "CODE404"になる。この場合はthrowしない
            console.log(res.data);
            if (res.data === null) {
              if ("CODE404" != res.error.code) {
                throw new HttpErrorResponse({
                  error: res.error,
                  status: 400,
                  statusText: "Bad Request",
                  url: self.getListApiParams.uri,
                });
              } else {
                // エラー情報が格納されている場合はエラーオブジェクトを返す
                // reject({
                //   error: res.error,
                //   errorHeader: res.errorHeader,
                //   errorDetail: res.errorDetail,
                // });
                resolve(null);
              }
            } else {
              // 正常な結果が格納されている場合、メソッド仕様のデータ型変数を返す
              resolve(res.data.details as Array<any>);
            }
          })
          .catch((err) => {
            console.error(err);
            console.log(`Api execution failed: ${err.message}`);
            reject(err);
          });
      })
    );
  }

  update(param: any): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.updateApiParams.needsAuth;
    apiExecuteModel.method = this.updateApiParams.method;
    // apiExecuteModel.path = environment.api.apiBase + this.updateApiParams.uri + "/" + param.makerid;
    apiExecuteModel.path = environment.api.apiBase + this.updateApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }

  delete(param: any): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.deleteApiParams.needsAuth;
    apiExecuteModel.method = this.deleteApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.deleteApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    console.log(apiExecuteModel);
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }

  addcustom(
    param: any
  ): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.addcustomApiParams.needsAuth;
    apiExecuteModel.method = this.addcustomApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.addcustomApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    console.log(apiExecuteModel);
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }

  updatecustom(param: any): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.updatecustomApiParams.needsAuth;
    apiExecuteModel.method = this.updatecustomApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.updatecustomApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }

  deletecustom(param: any): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.deletecustomApiParams.needsAuth;
    apiExecuteModel.method = this.deletecustomApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.deletecustomApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    console.log(apiExecuteModel);
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }

  deleteall(param: any): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.deleteallApiParams.needsAuth;
    apiExecuteModel.method = this.deleteallApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.deleteallApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    console.log(apiExecuteModel);
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }

  orderdecision(param: any): Promise<any> {
    let apiExecuteModel = new ApiExecuteModel();
    apiExecuteModel.isNeedsSigv4 = this.confirmApiParams.needsAuth;
    apiExecuteModel.method = this.confirmApiParams.method;
    apiExecuteModel.path = environment.api.apiBase + this.confirmApiParams.uri;
    apiExecuteModel.body = param;
    apiExecuteModel.headers = {
      "Content-Type": "application/json",
    };

    const self = this;
    console.log(apiExecuteModel);
    return new Promise<any>((resolve, reject) => {
      this.apiExecuteService
        .executeApi(apiExecuteModel)
        .then((res) => {
          console.log(res);
          if (res.error) {
            reject(res);
          } else {
            resolve(res.data);
          }
        })
        .catch((err) => {
          console.error(err);
          console.log(`Api execution failed: ${err.message}`);
          reject(err.error);
        });
    });
  }

}


