import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { XapptokenInterceptor } from 'app/jb/interceptor/xapptoken.interceptor';
import { MockHttpInterceptor } from 'app/jb/interceptor/mock-http-interceptor';
import { throwIfAlreadyLoaded } from 'app/@core/module-import-guard';

const INTERCEPTORS = [
  { provide: HTTP_INTERCEPTORS, useClass: XapptokenInterceptor, multi: true,},
  { provide: HTTP_INTERCEPTORS, useClass: MockHttpInterceptor, multi: true, },
];

@NgModule({
  imports: [
    CommonModule
  ],
})
export class JbInterceptorModule {
  constructor(@Optional() @SkipSelf() parentModule: JbInterceptorModule) {
    throwIfAlreadyLoaded(parentModule, "JbInterceptorModule");
  }

  static forRoot(): ModuleWithProviders<JbInterceptorModule> {
    return {
      ngModule: JbInterceptorModule,
      providers: [
        ...INTERCEPTORS
      ]
    };
  }
}
