<div class="row">
  <div [class]="getCardClass(card)" *ngFor="let card of appLayout.cardList">
    <nb-card
      *ngIf="card.buttonCard"
      [hidden]="card.hidden"
      [nbSpinner]="loading"
      [accent]="card.accent"
      [status]="card.status"
      nbSpinnerSize="giant"
      nbSpinnerStatus="success"
    >
      <div class="row" *ngFor="let row of card.rows">
        <nb-card-body>
          <nb-actions size="small">
            <nb-action [hidden]="col.hidden" *ngFor="let col of row.cols">
              <button
                *ngIf="col.type === 'submit'"
                [type]="col.type"
                nbButton
                ghost
                [size]="col.size ? col.size : 'medium'"
                [status]="col.status"
              >
                {{ col.label }}
              </button>
              <button
                *ngIf="col.type !== 'submit'"
                [type]="col.type"
                nbButton
                ghost
                [size]="col.size ? col.size : 'medium'"
                [status]="col.status"
                [disable]="col.disable"
                (click)="
                  onChildEvent({
                    type: 'buttonClick',
                    from: col.controlName,
                    value: col.onClickParam
                  })
                "
              >
                {{ col.label }}
              </button>
            </nb-action>
          </nb-actions>
        </nb-card-body>
      </div>
    </nb-card>
    <nb-card *ngIf="card.isBlankCard">
      <nb-card-body style="padding: 0"> </nb-card-body>
    </nb-card>
    <nb-card
      *ngIf="!card.buttonCard && !card.isBlankCard"
      [hidden]="card.hidden"
      [nbSpinner]="loading"
      [accent]="card.accent"
      [status]="card.status"
      nbSpinnerSize="giant"
      nbSpinnerStatus="success"
    >
      <nb-card-header *ngIf="card.name">
        {{ card.name }}
        <span *ngIf="card.tablerequired" class="required-mark">&nbsp;*</span>
        <span *ngFor="let headerbutton of card.headerbutton">
          <button
            *ngIf="headerbutton"
            [type]="headerbutton.type"
            nbButton
            ghost
            class="headerbutton"
            [size]="headerbutton.size ? headerbutton.size : 'medium'"
            [status]="headerbutton.status"
            [hidden]="headerbutton.hidden"
            (click)="
              onChildEvent({
                type: 'buttonClick',
                from: headerbutton.controlName,
                value: headerbutton.onClickParam
              })
            "
          >
            {{ headerbutton.label }}
          </button>
        </span>
      </nb-card-header>
      <nb-card-body>
        <div class="row" *ngFor="let row of card.rows">
          <div [class]="getColClass(col)" *ngFor="let col of row.cols">
            <button
              *ngIf="col.type === 'button'"
              [type]="col.type"
              nbButton
              ghost
              [size]="col.size ? col.size : 'medium'"
              [status]="col.status"
              [hidden]="col.hidden"
              [disabled]="col.disabled"
              (click)="
                onChildEvent({
                  type: 'buttonClick',
                  from: col.controlName,
                  value: col.onClickParam
                })
              "
            >
              {{ col.label }}
            </button>
            <jb-form-input
              *ngIf="col.type == 'input'"
              [mode]="col.mode"
              [label]="col.label"
              [parent]="parent"
              [controlName]="col.controlName"
              [type]="col.inputType"
              [maxlength]="col.maxlength"
              [hidden]="col.hidden"
              [messages]="messages"
              (blur)="onChildEvent($event)"
              (clickSearchIcon)="onChildEvent($event)"
              [numberCommmaSeparate]="col.numberCommmaSeparate"
            >
            </jb-form-input>
            <jb-form-radio
              *ngIf="col.type == 'radio'"
              [label]="col.label"
              [parent]="parent"
              [controlName]="col.controlName"
              [optionCode]="col.optionCode"
              [options]="col.options"
              [hidden]="col.hidden"
              [messages]="messages"
              (valueChange)="onChildEvent($event)"
            ></jb-form-radio>
            <jb-form-select
              *ngIf="col.type == 'select'"
              [label]="col.label"
              [parent]="parent"
              [controlName]="col.controlName"
              [optionCode]="col.optionCode"
              [options]="col.options"
              [optionInclude]="col.optionInclude"
              [hidden]="col.hidden"
              [messages]="messages"
              (selectedChange)="onChildEvent($event)"
            ></jb-form-select>
            <jb-form-select
              *ngIf="col.type == 'selectMultiple'"
              mode="multiple"
              [label]="col.label"
              [parent]="parent"
              [controlName]="col.controlName"
              [optionCode]="col.optionCode"
              [options]="col.options"
              [optionInclude]="col.optionInclude"
              [hidden]="col.hidden"
              [messages]="messages"
              (selectedChange)="onChildEvent($event)"
            ></jb-form-select>
            <jb-form-datepicker
              *ngIf="col.type == 'datepicker'"
              [label]="col.label"
              [parent]="parent"
              [controlName]="col.controlName"
              [hidden]="col.hidden"
              [messages]="messages"
            ></jb-form-datepicker>
            <jb-form-check
              *ngIf="col.type == 'check'"
              [label]="col.label"
              [parent]="parent"
              [controlName]="col.controlName"
              [checkLabel]="col.checkLabel"
              [hidden]="col.hidden"
              [messages]="messages"
              (change)="onChildEvent($event)"
            >
            </jb-form-check>
            <jb-form-textarea
              *ngIf="col.type == 'textarea'"
              [label]="col.label"
              [parent]="parent"
              [controlName]="col.controlName"
              [rows]="col.rows"
              [maxlength]="col.maxLength"
              [hidden]="col.hidden"
              [messages]="messages"
            ></jb-form-textarea>
            <jb-form-file
              *ngIf="col.type == 'file'"
              [label]="col.label"
              [parent]="parent"
              [controlName]="col.controlName"
              [hidden]="col.hidden"
              [messages]="messages"
            ></jb-form-file>
            <ng-container *ngIf="col.type == 'table'">
              <ng-container *ngIf="getformControl(col.controlName).touched">
                <ng-container
                  *ngIf="getformControl(col.controlName).errors?.apiError"
                >
                  <p
                    class="caption status-danger"
                    *ngFor="
                      let apiError of getformControl(col.controlName).errors
                        .apiError
                    "
                  >
                    {{ apiError.code + ": " + apiError.message }}
                  </p>
                </ng-container>
              </ng-container>
              <nb-select
                *ngIf="col.selectPerPage"
                placeholder="表示件数"
                class="mb-1 float-left"
                [(selected)]="col.perPage"
                size="small"
                (selectedChange)="
                  onChildEvent({
                    type: 'changePerPage',
                    from: col.controlName,
                    value: col
                  })
                "
              >
                <nb-option
                  *ngFor="
                    let num of col.selectPerPageOptions
                      ? col.selectPerPageOptions
                      : [10, 50, 100]
                  "
                  [value]="num"
                  >{{ num }}</nb-option
                >
              </nb-select>
              <ng2-smart-table
                [id]="col.controlName"
                [settings]="col.settings"
                [source]="col.source"
                (rowSelect)="
                  onChildEvent({
                    type: 'rowSelect',
                    from: col.controlName,
                    value: $event
                  })
                "
                (userRowSelect)="
                  onChildEvent({
                    type: 'userRowSelect',
                    from: col.controlName,
                    value: $event
                  })
                "
                (mouseover)="
                  onChildEvent({
                    type: 'mouseover',
                    from: col.controlName,
                    value: $event
                  })
                "
                (create)="
                  onChildEvent({
                    type: 'create',
                    from: col.controlName,
                    value: $event
                  })
                "
                (createConfirm)="
                  onChildEvent({
                    type: 'createConfirm',
                    from: col.controlName,
                    value: $event
                  })
                "
                (edit)="
                  onChildEvent({
                    type: 'edit',
                    from: col.controlName,
                    value: $event
                  })
                "
                (editConfirm)="
                  onChildEvent({
                    type: 'editConfirm',
                    from: col.controlName,
                    value: $event
                  })
                "
                (delete)="
                  onChildEvent({
                    type: 'delete',
                    from: col.controlName,
                    value: $event
                  })
                "
                (deleteConfirm)="
                  onChildEvent({
                    type: 'deleteConfirm',
                    from: col.controlName,
                    value: $event
                  })
                "

                (download)="
                  onChildEvent({
                    type: 'download',
                    from: col.controlName,
                    value: $event
                  })
                "
                (downloadConfirm)="
                  onChildEvent({
                    type: 'downloadConfirm',
                    from: col.controlName,
                    value: $event
                  })
                "
              ></ng2-smart-table>
            </ng-container>
          </div>
        </div>
      </nb-card-body>
    </nb-card>
  </div>
</div>
