import { ValidatorFn, FormGroup } from '@angular/forms';

/**
 * @classdesc パスワードバリデータークラス
 * @author tomonari yamada
 */
export class PasswordValidator {

  /**
   * パスワード登録時のチェックをする
   * (アルファベットの小文字(1つ以上),大文字(1つ以上),数字(0~9：1つ以上)/8文字以上)
   * @param message エラー時のメッセージをセット
   * @returns 妥当なパスワードでないバリデーションエラー
   *
   * @example <caption>sample-component.ts</caption>
   * //バリデータークラスのインポート
   * import { PasswordValidator } from 'app/jb/validator/password.validator';
   */
  public static checkPassword(): ValidatorFn {
    return (control: FormGroup): { [key: string]: any } => {
      if (control?.value != null && control.value != '') {
        const regex = /^(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])[!-~]{8,}$/;
        if (regex.test(control?.value) == false) {
          return { validatePassword: true };
        }
      }
      return null;
    }
  }
}
