<div [formGroup]="parent">
  <nb-form-field>
    <label for="remarks" class="label pull-top"
      >{{ label
      }}<span *ngIf="required" class="required-mark">&nbsp;*</span></label
    >
    <textarea
      [rows]="rows"
      nbInput
      fullWidth
      [formControlName]="controlName"
      shape="round"
      [placeholder]="placeholder"
      [maxlength]="maxlength"
      [status]="checkFormControlStatus(formControl)"
    ></textarea>
  </nb-form-field>
  <ng-container *ngIf="formControl.invalid && formControl.touched">
    <p class="caption status-danger" *ngIf="formControl.errors?.required">
      {{ messages.required }}
    </p>
    <ng-container *ngIf="formControl.errors?.apiError">
      <p
        class="caption status-danger"
        *ngFor="let apiError of formControl.errors.apiError"
      >
        {{ apiError.code + ": " + apiError.message }}
      </p>
    </ng-container>
  </ng-container>
</div>
