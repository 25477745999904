import { tap } from "rxjs/operators";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from "@angular/core";
import { FormGroup } from "@angular/forms";
import { NbThemeService } from "@nebular/theme";
import { Observable } from "rxjs";
import { LocalData } from "@akveo/ng2-completer";
import { LocalDataSource } from "ng2-smart-table";

@Component({
  selector: "jb-form-auto-layout",
  templateUrl: "./jb-form-auto-layout.component.html",
  styleUrls: ["./jb-form-auto-layout.component.scss"],
})
export class JbFormAutoLayoutComponent implements OnInit {
  @Input() parent: FormGroup;
  @Input() appLayout: any;
  @Input() loading: boolean = false;
  @Input() messages: any;
  @Output() childEvent: EventEmitter<any>;

  public materialTheme$: Observable<boolean>;
  public showMaterialInputs = false;

  // perPageOptions: Array<any> = [
  //   {
  //     value: "10",
  //     label: "10"
  //   }
  // ]

  constructor(
    private readonly themeService: NbThemeService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    this.childEvent = new EventEmitter<any>();
  }

  ngOnInit() {
    this.materialTheme$ = this.themeService.onThemeChange().pipe(
      tap((theme) => {
        const themeName: string = theme?.name || "";
        this.showMaterialInputs = themeName.startsWith("material");
      })
    );
  }

  onChildEvent(event: any) {
    if (event.type === "changePerPage") {
      let source: LocalDataSource = event.value.source;
      source.setPaging(1, event.value.perPage);
      // this.changeDetectorRef.detectChanges();
    } else {
      // テーブルのエラーメッセージを非表示
      if (event.type === "createConfirm" || event.type === "editConfirm") {
        this.getformControl(event.from).markAsUntouched();
      }
      this.childEvent.emit(event);
    }
  }

  /**
   * CSSクラス取得
   * 列幅省略は自動
   */
  getCardClass(layoutItem: any) {
    return `${
      layoutItem.width ? `col-lg-${layoutItem.width}` : `col-lg-auto`
    } ${layoutItem.class ? layoutItem.class : ""}`;
  }

  getColClass(layoutItem: any) {
    return `${
      layoutItem.width ? `col-md-${layoutItem.width}` : `col-md-auto`
    } ${layoutItem.class ? layoutItem.class : ""}`;
  }

  getformControl(controlName: string) {
    const formControl = this.parent.get(controlName);
    return formControl;
  }

}
