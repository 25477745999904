<div [formGroup]="parent">
  <label [for]="controlName" class="label"
    >{{ label
    }}<span *ngIf="required" class="required-mark">&nbsp;*</span></label
  >
  <nb-radio-group
    [name]="controlName"
    [formControlName]="controlName"
    [value]="formControl"
    [disabled]="formControl.disabled"
    [status]="checkFormControlStatus(formControl)"
    (valueChange)="onChange($event)"
  >
    <nb-radio *ngFor="let option of options" [value]="option.value">{{
      option.label
    }}</nb-radio>
  </nb-radio-group>
  <ng-container *ngIf="formControl.invalid && formControl.touched">
    <p class="caption status-danger" *ngIf="formControl.errors?.required">
      {{ messages.required }}
    </p>
    <ng-container *ngIf="formControl.errors?.apiError">
      <p
        class="caption status-danger"
        *ngFor="let apiError of formControl.errors.apiError"
      >
        {{ apiError.code + ": " + apiError.message }}
      </p>
    </ng-container>
  </ng-container>
</div>
